import React, { useState, useEffect } from "react";
import { Configs } from "../../common/config";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";
import Autocomplete from "@material-ui/lab/Autocomplete";
//--- Material Control
import {
  Button,
  TextField,
  IconButton,
  Tooltip,
  Popover,
  Fab,
} from "@material-ui/core";

//--- Material Icon
import AddCircle from "@material-ui/icons/AddCircle";
import CloseIcon from "@material-ui/icons/Close";
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchIcon from "@material-ui/icons/Search";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import RefreshIcon from "@material-ui/icons/Refresh";
import DeleteDialog from "../../components/dialog-delete/dialog-delete.view";

//--- Notifications
import ShowNotification from "../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../utils/configuration";
import * as viVN from "../../language/vi-VN.json";

//--- Redux store
import * as placeAction from "../../redux/store/place/place.store";
import * as placeKindAction from "../../redux/store/place-kind/place-kind.store";
import * as placeTypeAction from "../../redux/store/place-type/place-type.store";
import ListLandTypeManagement from "./list-place-kind-management/list-place-kind-management.view";
import EditLandTypeManagement from "./edit-place-kind-management/edit-place-kind-management.view";
import AddLandTypeManagement from "./add-place-kind-management/add-place-kind-management.view";
import * as appActions from "../../core/app.store";

function PlaceKindManagement(props) {
  const { showLoading } = props;
  const [placeKindModels, setPlaceKindModels] = useState();
  const [placeTypeModels, setPlaceTypeModels] = useState();
  const [totalItemCount, setTotalItemCount] = useState();
  const [name, setName] = useState();
  const [placeId, setplaceId] = useState();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("modifiedDate");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(Configs.DefaultPageSize);
  const [placeKindTemp, setPlaceKindTemp] = useState(placeKindModels);
  const [placeTypeId, setPlaceTypeId] = useState();
  const { register, handleSubmit } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  useEffect(() => {
    GetListPlaceType();
    GetListPlaceKind();
  }, []);

  const GetListPlaceKind = (
    pageIndex = 1,
    pageSize,
    sortExpression = orderBy + " " + order,
    nameSearch = name
  ) => {
    showLoading(true);
    setPage(pageIndex - 1);
    placeKindAction
      .GetListAll(pageIndex, pageSize, sortExpression, nameSearch, placeTypeId)
      .then((res) => {
        if (res && res.content) {
          setPlaceKindModels(res.content.items);
          setPlaceKindTemp(res.content.items);
          setTotalItemCount(res.content.totalItemCount);
          showLoading(false);
        }
      })
      .catch((err) => {
        showLoading(false);
      });
  };
  const GetListPlaceType = () => {
    showLoading(true);
    placeTypeAction
      .GetLookUp()
      .then((res) => {
        if (res && res.content) {
          setPlaceTypeModels(res.content);
          showLoading(false);
        }
      })
      .catch((err) => {
        showLoading(false);
      });
  };

  //--- Dialog
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleOpenAddDialog = () => {
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
  };

  const handleOpenEditDialog = (placeId) => {
    setplaceId(placeId);
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleOpenDeleteDialog = (placeId, status) => {
    setplaceId(placeId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  //--- Filter
  const [filterSection, setFilterSection] = useState(null);

  const handleClickFilter = (event) => {
    setFilterSection(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setFilterSection(null);
  };
  const ShowNotificationError = (messages) => {
    ShowNotification(messages, NotificationMessageType.Error);
  };

  const openFilter = Boolean(filterSection);
  const idFilter = openFilter ? "popoverSlider" : undefined;

  const handleClearAllField = () => {
    setName("");
    document.getElementById("formSearch").reset();
  };

  const onSubmit = async (data) => {
    await GetListPlaceKind(1, rowsPerPage, orderBy + " " + order, name);
    handleCloseFilter();
  };

  const refresh = () => {
    setName("");
    setOrderBy("modifiedDate");
    setOrder("desc");
    GetListPlaceKind(1, rowsPerPage);
  };

  const handleDelete = () => {
    placeKindAction.Delete(placeId).then(
      (res) => {
        if (res && res.content && res.content.status) {
          setName("");
          setOrderBy("modifiedDate");
          setOrder("desc");
          GetListPlaceKind(1, rowsPerPage);
          handleCloseDeleteDialog();
          ShowNotification(
            viVN.Success.Deleteplace,
            NotificationMessageType.Success
          );
        }
      },
      (err) => {
        err &&
          err.errorType &&
          ShowNotification(
            viVN.Errors[err.errorType],
            NotificationMessageType.Error
          );
      }
    );
  };
  const handleOnchangePlaceType = (event, newValue) => {
    if (newValue) {
      setPlaceTypeId(newValue.id);
    }
  };

  return (
    <div className="slider">
      <div className="d-sm-flex align-items-center justify-content-between mb-3">
        <h1 className="h3 mb-0 text-gray-800">
          Quản lý chi tiết loại địa điểm
          <Tooltip title="Tìm kiếm">
            <Fab
              color="primary"
              aria-label="filter"
              size="small"
              className="ml-2"
              aria-describedby={idFilter}
              onClick={handleClickFilter}
            >
              <FilterListIcon />
            </Fab>
          </Tooltip>
          <Tooltip title="Refresh">
            <Fab
              color="primary"
              aria-label="filter"
              size="small"
              onClick={refresh}
              className="ml-2"
            >
              <RefreshIcon />
            </Fab>
          </Tooltip>
          <Popover
            id={idFilter}
            open={openFilter}
            anchorEl={filterSection}
            onClose={handleCloseFilter}
            style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <div className="p-3" style={{ width: "30rem" }}>
              <div className="text-right border-bottom mb-3 pb-2">
                <IconButton
                  aria-label="close"
                  size="small"
                  onClick={handleCloseFilter}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </div>
              <form id="formSearch" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                  <label className="text-dark">
                    Tên chi tiết loại địa điểm{" "}
                  </label>
                  <TextField
                    className="w-100"
                    name="name"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    inputRef={register}
                  />
                </div>
                <div className="form-group">
                  {placeTypeModels && placeTypeModels.length > 0 && (
                    <Autocomplete
                      id="placeType"
                      options={placeTypeModels}
                      getOptionLabel={(option) => option.name}
                      fullWidth
                      onChange={(event, newValue) =>
                        handleOnchangePlaceType(event, newValue)
                      }
                      disableClearable={true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Chọn loại địa điểm "
                          size="small"
                          variant="outlined"
                        />
                      )}
                    />
                  )}
                </div>
                <div className="border-top">
                  <div className="row">
                    <div className="col-12 text-right mt-3">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={onSubmit}
                      >
                        <SearchIcon fontSize="small" /> Tìm kiếm
                      </Button>
                      <Button
                        variant="contained"
                        className="ml-2"
                        onClick={handleClearAllField}
                      >
                        <ClearAllIcon fontSize="small" /> Bỏ lọc
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </Popover>
        </h1>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenAddDialog}
          startIcon={<AddCircle />}
        >
          Thêm mới chi tiết loại địa điểm
        </Button>
      </div>
      {placeKindModels ? (
        <ListLandTypeManagement
          totalItemCount={totalItemCount}
          landTypeModels={placeKindModels}
          GetListLandType={GetListPlaceKind}
          name={name}
          editAction={handleOpenEditDialog}
          deleteAction={handleOpenDeleteDialog}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          order={order}
          orderBy={orderBy}
          page={page}
          rowsPerPage={rowsPerPage}
        />
      ) : (
        ""
      )}
      {openAddDialog && (
        <AddLandTypeManagement
          isOpen={openAddDialog}
          onClose={handleCloseAddDialog}
          onSuccess={handleCloseAddDialog}
          ShowNotificationError={ShowNotificationError}
          GetListLandType={GetListPlaceKind}
          rowsPerPage={rowsPerPage}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          showLoading={showLoading}
          placeKindModels={placeKindModels}
          placeTypeModels={placeTypeModels}
        />
      )}

      {openEditDialog && (
        <EditLandTypeManagement
          isOpen={openEditDialog}
          onClose={handleCloseEditDialog}
          onSuccess={handleCloseEditDialog}
          landTypeId={placeId}
          ShowNotificationError={ShowNotificationError}
          GetListLandType={GetListPlaceKind}
          rowsPerPage={rowsPerPage}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          showLoading={showLoading}
          placeKindModels={placeKindModels}
          placeTypeModels={placeTypeModels}
        />
      )}
      {openDeleteDialog ? (
        <DeleteDialog
          isOpen={openDeleteDialog}
          rowsPerPageCommon={rowsPerPage}
          onClose={handleCloseDeleteDialog}
          onSuccess={handleDelete}
        />
      ) : (
        ""
      )}
    </div>
  );
}
const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlaceKindManagement);
