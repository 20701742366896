import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";
import * as config from "../../../common/config";

const service = new Service();

export const GetListAll = (
  pageIndex = 1,
  pageSize = config.Configs.DefaultPageSize,
  sortExpression = "modifiedDate desc",
  name = ""
) => {
  const params = new URLSearchParams();
  params.append("pageIndex", pageIndex);
  params.append("pageSize", pageSize);
  params.append("sorting", sortExpression);
  name && params.append("projectName", name.trim());
  return service
    .get(ApiUrl.RealEstateProject_GetListAll, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
export const GetLookupRealEstateProject = () => {
  return service
    .get(ApiUrl.RealEstateProject_GetLookUp)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
export const GetDetail = (id) => {
  const params = new URLSearchParams();
  params.append("id", id);
  return service
    .get(ApiUrl.RealEstateProject_GetDetail.replace("{id}", id))
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const Create = (body) => {
  return service
    .post(ApiUrl.RealEstateProject_Create, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const Update = (body) => {
  return service
    .post(ApiUrl.RealEstateProject_Update, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const Delete = (id) => {
  return service
    .postParams(ApiUrl.RealEstateProject_Delete + "/" + id)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
export const CheckExistedFileCode = (fileCode) => {
  const params = new URLSearchParams();
  params.append("fileCode", fileCode);
  return service
    .get(ApiUrl.RealEstateProject_CheckExistedFileCode, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
