import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";
import * as config from "../../../common/config";

const service = new Service();

export const GetListAll = (
  pageIndex = 1,
  pageSize = config.Configs.DefaultPageSize,
  sortExpression = "modifiedDate desc",
  name = ""
) => {
  const params = new URLSearchParams();
  params.append("pageIndex", pageIndex);
  params.append("pageSize", pageSize);
  params.append("sorting", sortExpression);
  name && params.append("realEstateExchangeName", name);
  return service
    .get(ApiUrl.RealEstateExchange_GetListAll, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
export const GetLookUp = () => {
  return service
    .get(ApiUrl.RealEstateExchange_GetLookUp)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
export const GetDetail = (id) => {
  const params = new URLSearchParams();
  params.append("id", id);
  return service
    .get(ApiUrl.RealEstateExchange_GetDetail.replace("{id}", id))
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const Create = (body) => {
  return service
    .post(ApiUrl.RealEstateExchange_Create, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const Update = (body) => {
  return service
    .post(ApiUrl.RealEstateExchange_Update, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const Delete = (id) => {
  return service
    .postParams(ApiUrl.RealEstateExchange_Delete + "/" + id)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
export const CheckExistedBusinessCode = (businessCode) => {
  const params = new URLSearchParams();
  params.append("businessCode", businessCode);
  return service
    .get(ApiUrl.RealEstateExchange_CheckExistedBusinessCode, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
