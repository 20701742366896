import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";
import * as config from "../../../common/config"

const service = new Service();

export const GetListAll = (pageIndex = 1, pageSize = config.Configs.DefaultPageSize, sortExpression = "modifiedDate desc",name="",projectId="", type="") => {
    const params = new URLSearchParams();
    params.append('pageIndex',pageIndex);
    params.append('pageSize',pageSize);
    params.append('sorting',sortExpression);
    name && params.append('name',name);
    params.append('projectId', projectId)
    params.append('type', type)
    return service.get(ApiUrl.LegalDocument_GetListAll,params).then(res => { return res }).catch(err => { throw err });
}
export const GetListByProjectId = (pageIndex = 1, pageSize = config.Configs.DefaultPageSize, sortExpression = "modifiedDate desc",name="",projectId="", type="") => {
    const params = new URLSearchParams();
    params.append('pageIndex',pageIndex);
    params.append('pageSize',pageSize);
    params.append('sorting',sortExpression);
    name && params.append('name',name);
    params.append('projectId', projectId)
    params.append('type', type)
    return service.get(ApiUrl.LegalDocument_GetListByProjectId,params).then(res => { return res }).catch(err => { throw err });
}
export const GetDetail = (id) => {
    const params = new URLSearchParams();
    params.append("id", id);
    return service.get(ApiUrl.LegalDocument_GetDetail.replace("{id}",id)).then(res => { return res }).catch(err => { throw err });
}

export const Create = (body) => {
    return service.post(ApiUrl.LegalDocument_Create, body).then(res => { return res }).catch(err => { throw err });
}

export const Update = (body) => {
    return service.post(ApiUrl.LegalDocument_Update, body).then(res => { return res }).catch(err => { throw err });
}

export const Delete = (id) => {
    return service.postParams(ApiUrl.LegalDocument_Delete + "/" + id ).then(res => { return res }).catch(err => { throw err });
}
export const GetLookupLegalDocument = () => {
    return service
      .get(ApiUrl.LegalDocument_GetLookUp)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
