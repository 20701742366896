
import { GetLookupCommune } from "../redux/store/commune-management/commune.store";
import { GetLookUp as getLookupProjectType } from "../redux/store/project-type/project-type.store";
import { GetLookUp as getLookupProjectKind } from "../redux/store/project-kind/project-kind.store";
import { GetLookUpInvestor } from "../redux/store/investor/investor.store";
import { GetLookUpPlanning } from "../redux/store/planning/planning.store";
import {GetLookUpBusinessStatus} from "../redux/store/business-status-management/business-status-management.store";
import {GetLookupLegalDocument} from "../redux/store/legal-document-management/legal-document-management.store";
import ShowNotification from "../components/react-notifications/react-notifications";
import * as viVN from "../language/vi-VN.json";
import {NotificationMessageType} from "./configuration";
import { GetLookupDistrict } from "../redux/store/district-management/district.store";
import {GetLookupRealEstateProject} from "../redux/store/real-estate-project/real-estate-project.store";
import {GetLookUpConstructionRealEstateType} from "../redux/store/construction-real-estate-type-management/construction-real-estate-type-management.store";
import { GetLookUpConstructionCategory } from "../redux/store/construction-category-management/construction-category-management.store";
import { GetLookUpProjectStatus } from "../redux/store/project-status-management/project-status-management.store";
import { GetLookUpAreaType } from "../redux/store/area-type-management/area-type-management.store";

export const LookupDistrict = () => {
    return new Promise((resolve, reject) => {
      GetLookupDistrict().then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    });
  };

export const LookupProjectType = () => {
    return new Promise((resolve, reject) => {
        getLookupProjectType().then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    });
  };

export const LookupProjectKind = () => {
    return new Promise((resolve, reject) => {
        getLookupProjectKind().then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    });
  };

export const LookupInvestor = () => {
    return new Promise((resolve, reject) => {
        GetLookUpInvestor().then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    });
  };

// export const LookupAreaType = () => {
//     return {
//         "content": [
//             {
//                 "id": 1,
//                 "name": "Nội thị"
//             },
//             {
//                 "id": 2,
//                 "name": "Nông thôn"
//             }
//         ],
//         "err": null
//     }
//   };

export const LookupPlanning = () => {
    return new Promise((resolve, reject) => {
        GetLookUpPlanning().then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    });
  };
  export const LookupBusinessStatus = () => {
    return new Promise((resolve, reject) => {
      GetLookUpBusinessStatus().then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    });
  };
  export const LookupLegalDocument = () => {
    return new Promise((resolve, reject) => {
      GetLookupLegalDocument().then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    });
  };
  export const LookupRealEstateProject = () => {
    return new Promise((resolve, reject) => {
      GetLookupRealEstateProject().then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    });
  };
  export const LookupConstructionRealEstateType = () => {
    return new Promise((resolve, reject) => {
      GetLookUpConstructionRealEstateType().then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    });
  };
  export const LookupConstructionCategory = () => {
    return new Promise((resolve, reject) => {
      GetLookUpConstructionCategory().then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    });
  };
  export const LookupProjectStatus = () => {
    return new Promise((resolve, reject) => {
      GetLookUpProjectStatus().then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    });
  };
  export const LookupAreaType = () => {
    return new Promise((resolve, reject) => {
      GetLookUpAreaType().then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    });
  };