export const UrlCollection = {
  Home: "/",
  Slider: "/he-thong/bai-viet-trang-chu",
  PlanningAnnouncementProcess: "/qhdt/quy-trinh-cong-bo-quy-hoach",
  ProjectManagement:"/qhdt/quan-ly-du-an-dtxd",
  MapProjectManagement:"/qhdt/ban-do-quan-ly-du-an-dtxd",
  LandUsePLanningManagement:"/qhdt/quan-ly-quy-hoach-ke-hoach-sdd",
  TrafficPlanningManagement:"/qhdt/quan-ly-quy-hoach-giao-thong",
  EmailTemplate: "/he-thong/email-template",
  RoleManagement: "/he-thong/role-management",
  ContactManagement: "/he-thong/contact-management",
  EmailGenerated: "/he-thong/email-generated",
  UserManagement: "/he-thong/quan-ly-nguoi-dung",
  StreetManagement: "/he-thong/quan-ly-duong",
  UnitManagement: "/he-thong/quan-ly-don-vi",
  CommuneManagement: "/he-thong/quan-ly-xa-phuong",
  DistrictManagement: "/he-thong/quan-ly-quan-huyen",
  ProvinceManagement: "/he-thong/quan-ly-thanh-pho",
  LinkGroup: "/he-thong/danh-sach-nhom",
  ServiceLink: "/he-thong/danh-sach-lien-ket",
  UserLogHistory: "/he-thong/nhat-ky-nguoi-dung",
  LandType: "/he-thong/quan-ly-loai-dat",
  LandTypeDetail: "/he-thong/quan-ly-chi-tiet-loai-dat",
  News: "/qhdt/tin-tuc",

    CategoryParent: "/category-parent",
    DocumentManagement: "/document-management",
  //--- Account
  Login: "/dang-nhap",
  LoginFromMobile: "/login-from-mobile",
  Register: "/dang-ky",
  ForgotPassword: "/quen-mat-khau",
  ConfirmCode: "/xac-nhan",
  ResetPassword: "/dat-lai-mat-khau",
  MapData: '/qhdt/ban-do',
  CreateMapData: '/thiet-dat-thong-so-ban-do/:mapKey',
  AccessDenied: '/403',
  OpinionForm: '/he-thong/tao-form-y-kien',

  //--- Table structure
  TableStructure: '/he-thong/sua-cau-truc-bang',
  Place: "/he-thong/quan-ly-dia-diem",
  PlaceType: "/he-thong/quan-ly-loai-dia-diem",
  PlaceKind: "/he-thong/quan-ly-chi-tiet-loai-dia-diem",
  ProjectType: "/he-thong/quan-ly-chi-tiet-loai-du-an",
  ProjectKind: "/he-thong/quan-ly-loai-du-an",
  //---remote sensing

  MapRemoteSensing: "/ban-do-vien-tham",
  HistoryRemoteSensing: "/lich-su-bien-dong",
  PhotoGalleryRemoteSensing: "/kho-anh-hien-trang",
  InfomationTableRemoteSensing: "/ban-bieu-thong-tin",
  RemoteSensingManagement: "/quan-ly-bien-dong",
  RemoteSensingPending: "/danh-sach-cho",
  RemoteSensingList: "/danh-sach-bien-dong",

  //---quản lý dự án nhà ở và bất động sản
  HousingProjectManagement:"/qhdt/quan-ly-du-an-nha-o-bds",
  RealEstateProjectManagement:"/qhdt/quan-ly-du-an-bds",
  ConstructionRealEstateManagement:"/qhdt/quan-ly-cong-trinh-bds",
  LegalDocumentManagement:"/qhdt/quan-ly-van-ban-phap-ly",
  ListHousingProject:"/qhdt/danh-sach-du-an-nha-o",
  ListRealEstateProject: "/qhdt/danh-sach-du-an-bat-dong-san",
  ListConstructionProject : "/qhdt/danh-sach-cong-trinh-bat-dong-san",
  ListRealEstateExchange:"/qhdt/danh-sach-san-giao-dich-bat-dong-san",
  MapHousingProjectManagement:"/qhdt/ban-do-quan-ly-du-an-nha-o-bds",
  ListLegalDocumentManagement:"/qhdt/danh-sach-van-ban-phap-luat",
};
