import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

//--- Material Control
import {
  DialogActions,
  Button,
  TextField,
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
  IconButton,
  makeStyles,
  Checkbox,
} from "@material-ui/core";

//--- Material Icon
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import { NotificationMessageType,  changeAlias } from "../../../utils/configuration";
import * as viVN from "../../../language/vi-VN.json";
import * as action from "../../../redux/store/housing-project/housing-project.store";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as helper from "../../../utils/helper.js";
import DateFnsUtils from "@date-io/date-fns";
import viLocale from "date-fns/locale/vi";
import dateformat from "dateformat";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import FileManagement from "../../../components/file_management/file_management";


const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formControl: {
    margin: theme.spacing(1),
  },
}));

export default function AddHousingProjectManagement(props) {
  const classes = useStyles();
  const {
    isOpen,
    onClose,
    onSuccess,
    GetListHousingProject,
    rowsPerPage,
    showLoading,
  } = props;
  const { register, handleSubmit, errors, setValue, clearErrors, setError } =
    useForm({
      mode: "all",
      reValidateMode: "onBlur",
    });
  const [isImageShow, setImageShow] = useState(false);
  const [files, setFiles] = useState([]);
  const [filesTemp, setFilesTemp] = useState([]);
  const [lookupDistrictModel, setLookupDistrictModel] = useState([]);
  const [districtId, setDistrictId] = useState([]);
  const [lookupAreaTypeModel, setLookupAreaTypeModel] = useState([]);
  const [areaTypeId, setAreaTypeId] = useState([]);
  const [lookupProjectKindModel, setLookupProjectKindModel] = useState([]);
  const [projectKindId, setProjectKindId] = useState([]);
  const [lookupProjectTypeModel, setLookupProjectTypeModel] = useState([]);
  const [projectTypeId, setProjectTypeId] = useState([]);
  const [lookupInvestorModel, setLookupInvestorModel] = useState([]);
  const [investorId, setInvestorId] = useState([]);
  const [lookupProjectStatusModel, setLookupProjectStatusModel] = useState([]);
  const [projectStatusId, setProjectStatusId] = useState([]);
  const [lookupConstructionCategoryModel, setLookupConstructionCategoryModel] =
    useState([]);
  const [constructionCategoryId, setConstructionCategoryId] = useState([]);
  const [legalDocumentsId, setLegalDocumentsId] = useState([]);
  const [lookupLegalDocumentsModel, setLookupLegalDocumentsModel] = useState(
    []
  );
  const [lookupPlanningModel, setLookupPlanningModel] = useState([]);
  const [planningId, setPlanningId] = useState([]);
  const [isExistFileCode, setIsExistFileCode] = useState(false);
  const fileCode = document.getElementById("fileCode");
  //File
  const [zipFiles, setZipFiles] = useState([]);
  const [zipFilesTemp, setZipFilesTemp] = useState([]);
  const [isShowZip, setShowZip] = useState(false);
  const [zipName, setZipName] = useState(null);
  useEffect(() => {
    onGetData();
  }, []);

  const onGetData = () => {
    showLoading(true);
    Promise.all([
      helper.LookupDistrict(),
      helper.LookupAreaType(),
      helper.LookupProjectStatus(),
      helper.LookupProjectKind(),
      helper.LookupProjectType(),
      helper.LookupInvestor(),
      helper.LookupLegalDocument(),
      helper.LookupConstructionCategory(),
      helper.LookupPlanning(),
    ])
      .then((res) => {
        const [
          lookupDistrict,
          lookupAreaType,
          lookupProjectStatus,
          lookupProjectKind,
          lookupProjectType,
          LookupInvestor,
          lookupLegalDocument,
          lookupConstructionCategory,
          lookupPlanningModel,
        ] = res;
        lookupDistrict &&
          lookupDistrict.content &&
          setLookupDistrictModel(lookupDistrict.content);
        lookupAreaType &&
          lookupAreaType.content &&
          setLookupAreaTypeModel(lookupAreaType.content);
        lookupProjectStatus &&
          lookupProjectStatus.content &&
          setLookupProjectStatusModel(lookupProjectStatus.content);
        lookupProjectKind &&
          lookupProjectKind.content &&
          setLookupProjectKindModel(lookupProjectKind.content);
        lookupProjectType &&
          lookupProjectType.content &&
          setLookupProjectTypeModel(lookupProjectType.content);
        LookupInvestor &&
          LookupInvestor.content &&
          setLookupInvestorModel(LookupInvestor.content);
        lookupLegalDocument &&
          lookupLegalDocument.content &&
          setLookupLegalDocumentsModel(lookupLegalDocument.content);
        lookupConstructionCategory &&
          lookupConstructionCategory.content &&
          setLookupConstructionCategoryModel(
            lookupConstructionCategory.content
          );
        lookupPlanningModel &&
          lookupPlanningModel.content &&
          setLookupPlanningModel(lookupPlanningModel.content);
        showLoading(false);
      })
      .catch((err) => {
        showLoading(false);
      });
  };
  const onSubmit = (data) => {
    if (!data) {
      return;
    } else if (isExistFileCode) {
      if (fileCode) fileCode.focus();
      return;
    } else {
      let formData = new FormData();
      formData.append("fileCode", data?.fileCode);
      formData.append("projectName", data?.projectName);
      formData.append("address", data?.address);
      formData.append("areaTypeId", areaTypeId || data?.areaTypeId);
      formData.append("districtId", districtId || data?.districtId);
      formData.append("projectKindId", projectKindId || data?.projectKindId);
      formData.append("projectTypeId", projectTypeId || data?.projectTypeId);
      formData.append("investorId", investorId || data?.investorId);
      formData.append("areaSize", data?.areaSize);
      formData.append("totalInvestment", data?.totalInvestment);
      formData.append("executionTime", data?.executionTime);
      formData.append("constructionProgress", data?.constructionProgress);
      formData.append(
        "projectStatusId",
        projectStatusId || data?.projectStatusId
      );
      formData.append(
        "constructionCategoryId",
        constructionCategoryId || data?.constructionCategoryId
      );
      formData.append("numberHouse", data?.numberHouse);
      formData.append("areaHouse", data?.areaHouse);
      formData.append("averageSalesPrice", data?.averageSalesPrice);
      formData.append("averageRentalPrice", data?.averageRentalPrice);
      formData.append("numberHouseProvided", data?.numberHouseProvided);
      formData.append("areaHouseProvided", data?.areaHouseProvided);
      formData.append("averageSalesPrice", data?.averageSalesPrice);
      formData.append("areaOfficeLease", data?.areaOfficeLease);
      formData.append("areaCommercialSpace", data?.areaCommercialSpace);
      formData.append("planningId", planningId || data?.planningId);
      formData.append("approvalUnit", data?.approvalUnit);
      formData.append("constructionLicense", data?.constructionLicense);
      formData.append(
        "constructionLicensingUnit",
        data?.constructionLicensingUnit
      );
      formData.append("note", data?.note);
      // legalDocumentsId.forEach((id, index) => {
      //   formData.append(`legalDocumentsId[${index}]`, id);
      // });
      //formData.append("shpFile", shapeFile);
      if (zipFiles && zipFiles.length > 0)
        formData.append("shapeFileId", zipFiles[0].fileId);
      action
        .Create(formData)
        .then((result) => {
          if (result && result.content && result.content.status === true) {
            GetListHousingProject(1, rowsPerPage);
            showLoading(false);
            onSuccess();
            ShowNotification(
              viVN.Success.CreateSuccess,
              NotificationMessageType.Success
            );
          }
        })
        .catch((err) => {
          showLoading(false);
          onSuccess();
          ShowNotification(
            viVN.Errors[err.errorType],
            NotificationMessageType.Error
          );
        });
    }
  };

  let timeout = 0;
  const handleCheckCodeExist = (event) => {
    let value = event;

    if (!value) {
      setError("fileCode", { type: "required" });
    } else {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        CheckExistedHousingProject(value);
      }, 100);
    }
  };
  const CheckExistedHousingProject = (fileCode) => {
    action
      .CheckExistedFileCode(fileCode)
      .then((res) => {
        if (res && res.content && res.content.status) {
          clearErrors("fileCode");
          setIsExistFileCode(false);
        } else {
          setError("fileCode", { type: "validate" });
          setIsExistFileCode(true);
        }
      })
      .catch((err) => {
        setError("fileCode", { type: "validate" });
        setIsExistFileCode(true);
      });
  };
  const handleOnchangeDistrict = (event, newValue) => {
    if (newValue) {
      setDistrictId(newValue.id);
    }
  };
  const handleOnchangeProjectKind = (event, newValue) => {
    if (newValue) {
      setProjectKindId(newValue.id);
    }
  };
  const handleOnchangeProjectType = (event, newValue) => {
    if (newValue) {
      setProjectTypeId(newValue.id);
    }
  };
  const handleOnchangeInvestor = (event, newValue) => {
    if (newValue) {
      setInvestorId(newValue.id);
    }
  };
  const handleOnchangeProjectStatus = (event, newValue) => {
    if (newValue) {
      setProjectStatusId(newValue.id);
    }
  };
  const handleOnchangeLegalDocument = (event, newValue) => {
    if (newValue) {
      const newIds = newValue.map((item) => item.id);
      setLegalDocumentsId(newIds);
    } else {
      setLegalDocumentsId([]);
    }
  };
  const handleOnchangeConstructionCategory = (event, newValue) => {
    if (newValue) {
      setConstructionCategoryId(newValue.id);
    }
  };
  const handleOnchangeAreaType = (event, newValue) => {
    if (newValue) {
      setAreaTypeId(newValue.id);
    }
  };
  const handleOnchangePlanning = (event, newValue) => {
    if (newValue) {
      setPlanningId(newValue.id);
    }
  };
  // const [shapeFile, setShapeFile] = useState(null);

  // const hanleOpenSelectFile = () => {
  //   document.getElementById("shapeFile").click();
  // };

  // const handleFileChange = (event) => {
  //   clearErrors("shapeFile");
  //   const selectedFile = event.target.files[0];

  //   if (!selectedFile) {
  //     setShapeFile(null);
  //     return;
  //   }
  //   setValue("shapeFile", selectedFile);
  //   if (
  //     selectedFile &&
  //     (selectedFile.type === "application/zip" ||
  //       selectedFile.type === "application/x-zip-compressed" ||
  //       selectedFile.type === "application/x-compressed")
  //   ) {
  //     setShapeFile(selectedFile);
  //   } else {
  //     setShapeFile(null);
  //     ShowNotification(
  //       "Shape file không phù hợp.",
  //       NotificationMessageType.Error
  //     );
  //   }
  // };
  const onOpenSelectZipFile = () => {
    setShowZip(true);
    setZipFilesTemp(zipFiles);
    setZipName(null);
  };

  const onCloseSelectZipFile = () => {
    setShowZip(false);
    setZipFiles(zipFilesTemp);
  };

  const onSaveSelectZipFile = () => {
    setShowZip(false);
    setZipName(zipFiles[0].fileName);
  };
  return (
    <div>
      <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="md">
        <DialogTitle disableTypography className="border-bottom">
          <Typography variant="h6">Thêm dự án nhà ở</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <DialogContent className="pt-4 pb-2">
            <div className="form-group row">
              <div className="col-6">
                <label className="text-dark">
                Mã dự án<span className="required"></span>
                </label>
                <TextField
                  fullWidth
                  type="text"
                  name="fileCode"
                  className="w-100"
                  inputRef={register({
                    required: true,
                    validate: handleCheckCodeExist,
                  })}
                  onChange={(e) => {
                    setValue("fileCode", changeAlias(e.target.value), {
                      shouldDirty: true,
                    });
                  }}
                  id="fileCode"
                  error={
                    (errors.fileCode &&
                      errors.fileCode.type === "required") ||
                    (errors.fileCode &&
                      errors.fileCode.type === "validate")
                  }
                  variant="outlined"
                  size="small"
                />
                {errors.fileCode &&
                  errors.fileCode.type === "required" && (
                    <span className="error">Trường này là bắt buộc</span>
                  )}
                {errors.fileCode &&
                  errors.fileCode.type === "validate" && (
                    <span className="error">Mã dự án đã tồn tại</span>
                  )}
              </div>

              <div className="col-6">
                <label className="text-dark">
                  Tên dự án<span className="required"></span>
                </label>

                <TextField
                  fullWidth
                  inputRef={register({ required: true })}
                  type="text"
                  name="projectName"
                  error={
                    errors.projectName && errors.projectName.type === "required"
                  }
                  variant="outlined"
                  size="small"
                />
                {errors.projectName &&
                  errors.projectName.type === "required" && (
                    <span className="error">Trường này là bắt buộc</span>
                  )}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-6">
                <label className="text-dark">Địa chỉ</label>

                <TextField
                  fullWidth
                  type="text"
                  name="address"
                  className="w-100"
                  inputRef={register}
                  variant="outlined"
                  size="small"
                />
              </div>
              {lookupAreaTypeModel && lookupAreaTypeModel.length > 0 && (
                <div className="col-6">
                  <label className="text-dark">Loại khu vực</label>
                  <Autocomplete
                    options={lookupAreaTypeModel}
                    getOptionLabel={(option) =>
                      typeof option === "string" ? option : option.name
                    }
                    onChange={(event, newValue) =>
                      handleOnchangeAreaType(event, newValue)
                    }
                    renderInput={(params) => (
                      <TextField
                        name={`areaTypeId`}
                        inputRef={register}
                        {...params}
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                </div>
              )}
            </div>

            <div className="form-group row">
              {lookupDistrictModel && lookupDistrictModel.length > 0 && (
                <div className="col-6">
                  <label className="text-dark">Huyện</label>
                  <Autocomplete
                    options={lookupDistrictModel}
                    getOptionLabel={(option) =>
                      typeof option === "string" ? option : option.name
                    }
                    onChange={(event, newValue) => {
                      handleOnchangeDistrict(event, newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        name="districtId"
                        inputRef={register()}
                        {...params}
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                </div>
              )}

              {lookupProjectKindModel && lookupProjectKindModel.length > 0 && (
                <div className="col-6 col-md-6 col-lg-6">
                  <label className="text-dark">Loại dự án</label>
                  <Autocomplete
                    options={lookupProjectKindModel}
                    getOptionLabel={(option) =>
                      typeof option === "string" ? option : option.name
                    }
                    onChange={(event, newValue) =>
                      handleOnchangeProjectKind(event, newValue)
                    }
                    renderInput={(params) => (
                      <TextField
                        name="projectKindId"
                        inputRef={register}
                        {...params}
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                </div>
              )}
            </div>

            <div className="form-group row">
              {lookupProjectTypeModel && lookupProjectTypeModel.length > 0 && (
                <div className="col-6 col-md-6 col-lg-6">
                  <label className="text-dark">Chi tiết loại dự án</label>
                  <Autocomplete
                    options={lookupProjectTypeModel}
                    getOptionLabel={(option) =>
                      typeof option === "string" ? option : option.name
                    }
                    onChange={(event, newValue) =>
                      handleOnchangeProjectType(event, newValue)
                    }
                    renderInput={(params) => (
                      <TextField
                        name="projectTypeId"
                        inputRef={register}
                        {...params}
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                </div>
              )}
              {lookupInvestorModel && lookupInvestorModel.length > 0 && (
                <div className="col-6">
                  <label className="text-dark">Chủ đầu tư</label>
                  <Autocomplete
                    options={lookupInvestorModel}
                    getOptionLabel={(option) =>
                      typeof option === "string" ? option : option.name
                    }
                    onChange={(event, newValue) =>
                      handleOnchangeInvestor(event, newValue)
                    }
                    renderInput={(params) => (
                      <TextField
                        name="investorId"
                        inputRef={register}
                        {...params}
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                </div>
              )}
            </div>
            <div className="form-group row">
              <div className="col-6">
                <label className="text-dark">Quy mô (ha)</label>

                <TextField
                  fullWidth
                  type="number"
                  name="areaSize"
                  className="w-100"
                  inputRef={register}
                  variant="outlined"
                  size="small"
                />
              </div>
              <div className="col-6">
                <label className="text-dark">Tổng mức đầu tư</label>

                <TextField
                  fullWidth
                  inputRef={register}
                  type="text"
                  name="totalInvestment"
                  error={errors.totalInvestment}
                  variant="outlined"
                  size="small"
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-6">
                <label className="text-dark">Thời gian thực hiện</label>
                <TextField
                  fullWidth
                  type="date"
                  name="executionTime"
                  className="w-100"
                  inputRef={register}
                  variant="outlined"
                  size="small"
                />
              </div>
              <div className="col-6">
                <label className="text-dark">Tiến độ thực hiện</label>

                <TextField
                  fullWidth
                  inputRef={register}
                  type="text"
                  name="constructionProgress"
                  variant="outlined"
                  size="small"
                />
              </div>
            </div>
            <div className="form-group row">
              {lookupProjectStatusModel &&
                lookupProjectStatusModel.length > 0 && (
                  <div className="col-6">
                    <label className="text-dark">Trạng thái dự án</label>
                    <Autocomplete
                      options={lookupProjectStatusModel}
                      getOptionLabel={(option) =>
                        typeof option === "string" ? option : option.name
                      }
                      onChange={(event, newValue) =>
                        handleOnchangeProjectStatus(event, newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          name={`projectStatusId`}
                          {...params}
                          variant="outlined"
                          inputRef={register}
                          size="small"
                        />
                      )}
                    />
                  </div>
                )}
              {lookupConstructionCategoryModel &&
                lookupConstructionCategoryModel.length > 0 && (
                  <div className="col-6">
                    <label className="text-dark">Danh mục công trình</label>
                    <Autocomplete
                      options={lookupConstructionCategoryModel}
                      getOptionLabel={(option) =>
                        typeof option === "string" ? option : option.name
                      }
                      onChange={(event, newValue) =>
                        handleOnchangeConstructionCategory(event, newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          name={`constructionCategoryId`}
                          {...params}
                          variant="outlined"
                          inputRef={register}
                          size="small"
                        />
                      )}
                    />
                  </div>
                )}
            </div>
            <div className="form-group row">
              <div className="col-6">
                <label className="text-dark">Số nhà</label>
                <TextField
                  fullWidth
                  type="number"
                  name="numberHouse"
                  className="w-100"
                  inputRef={register}
                  variant="outlined"
                  size="small"
                />
              </div>
              <div className="col-6">
                <label className="text-dark">Khu vực nhà</label>

                <TextField
                  fullWidth
                  type="number"
                  name="areaHouse"
                  className="w-100"
                  inputRef={register}
                  variant="outlined"
                  size="small"
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-6">
                <label className="text-dark">Giá bán bình quân</label>

                <TextField
                  fullWidth
                  type="number"
                  name="averageSalesPrice"
                  className="w-100"
                  inputRef={register}
                  variant="outlined"
                  size="small"
                />
              </div>
              <div className="col-6">
                <label className="text-dark">Giá thuê bình quân</label>

                <TextField
                  fullWidth
                  inputRef={register}
                  type="number"
                  name="averageRentalPrice"
                  variant="outlined"
                  size="small"
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-6">
                <label className="text-dark">Số nhà cung cấp</label>

                <TextField
                  fullWidth
                  inputRef={register}
                  type="number"
                  name="numberHouseProvided"
                  variant="outlined"
                  size="small"
                />
              </div>
              <div className="col-6">
                <label className="text-dark">Khu vực nhà cung cấp</label>

                <TextField
                  fullWidth
                  inputRef={register}
                  type="number"
                  name="areaHouseProvided"
                  variant="outlined"
                  size="small"
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-6">
                <label className="text-dark">Khu vực văn phòng cho thuê</label>

                <TextField
                  fullWidth
                  inputRef={register}
                  type="number"
                  name="areaOfficeLease"
                  variant="outlined"
                  size="small"
                />
              </div>
              <div className="col-6">
                <label className="text-dark">
                  Khu vực không gian thương mại
                </label>

                <TextField
                  fullWidth
                  inputRef={register}
                  type="number"
                  name="areaCommercialSpace"
                  variant="outlined"
                  size="small"
                />
              </div>
            </div>
            <div className="form-group row">
              {lookupPlanningModel && lookupPlanningModel.length > 0 && (
                <div className="col-6">
                  <label className="text-dark">Phê duyệt quy hoạch</label>
                  <Autocomplete
                    options={lookupPlanningModel}
                    getOptionLabel={(option) =>
                      typeof option === "string" ? option : option.name
                    }
                    onChange={(event, newValue) =>
                      handleOnchangePlanning(event, newValue)
                    }
                    renderInput={(params) => (
                      <TextField
                        name="planningId"
                        {...params}
                        variant="outlined"
                        inputRef={register}
                        size="small"
                      />
                    )}
                  />
                </div>
              )}
              <div className="col-6">
                <label className="text-dark">Đơn vị phê duyệt</label>

                <TextField
                  fullWidth
                  inputRef={register}
                  type="text"
                  name="approvalUnit"
                  variant="outlined"
                  size="small"
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-6">
                <label className="text-dark">Giấy phép xây dựng</label>

                <TextField
                  fullWidth
                  inputRef={register}
                  type="text"
                  name="constructionLicense"
                  variant="outlined"
                  size="small"
                />
              </div>
              <div className="col-6">
                <label className="text-dark">Đơn vị cấp phép xây dựng</label>

                <TextField
                  fullWidth
                  inputRef={register}
                  type="text"
                  name="constructionLicensingUnit"
                  variant="outlined"
                  size="small"
                />
              </div>
            </div>
            {/* <div className="form-group row">
              {lookupLegalDocumentsModel &&
                lookupLegalDocumentsModel.length > 0 && (
                  <div className="col-6">
                    <label className="text-dark">
                      Danh sách văn bản pháp lý
                    </label>
                    <Autocomplete
                      multiple
                      options={lookupLegalDocumentsModel}
                      getOptionLabel={(option) =>
                        typeof option === "string" ? option : option.name
                      }
                      onChange={(event, newValue) =>
                        handleOnchangeLegalDocument(event, newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          name="legalDocumentsId"
                          inputRef={register}
                          {...params}
                          variant="outlined"
                          size="small"
                        />
                      )}
                    />
                  </div>
                )}
            </div> */}
            <div className="form-group row">
              <div className="col-12">
                <label className="text-dark">Ghi chú</label>
                <textarea
                  name="note"
                  rows="5"
                  className="form-control"
                  ref={register}
                ></textarea>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-6">
                <label className="text-dark">Shape File</label>
                <span className="required"></span>
                 <TextField
                  fullWidth
                  type="text"
                  name="shpName"
                  value={zipName}
                  placeholder="Hãy tải file zip lên."
                  disabled
                  variant="outlined"
                  size="small"
                  className="w-100"
                  inputRef={register({
                    required: true,
                  })}
                  error={
                    (errors.shpName &&
                      errors.shpName.type === "required") 
                  }
                />
                {errors.shpName &&
                  errors.shpName.type === "required" && (
                    <span className="error">Trường này là bắt buộc</span>
                  )}
               
                <div className="mt-2">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onOpenSelectZipFile}
                  >
                    Chọn shape file
                  </Button>
                </div>
              </div>
            </div>
            {/* <div className="form-group">
              <label className="text-dark">
                Shape File<span className="required"></span>
              </label>
              {shapeFile &&
                shapeFile.type === "application/x-zip-compressed" && (
                  <div style={{ width: "150px" }}>
                    <img
                      src={require("../../../assets/images/zip-file-format.png")}
                      alt={shapeFile?.name || "Default"}
                      className="img-fluid mb-2"
                      style={{
                        width: "auto",
                        height: "auto",
                        maxWidth: "100%",
                        maxHeight: "100%",
                      }}
                    />
                    <p style={{ color: "black" }} className="error">
                      {shapeFile?.name}
                    </p>
                  </div>
                )}

              {shapeFile && shapeFile.type === "application/x-compressed" && (
                <div style={{ width: "150px" }}>
                  <img
                    src={require("../../../assets/images/rar-file-format.png")}
                    alt={shapeFile?.name || "Default"}
                    className="img-fluid mb-2"
                    style={{
                      width: "auto",
                      height: "auto",
                      maxWidth: "100%",
                      maxHeight: "100%",
                    }}
                  />
                  <p style={{ color: "black" }} className="error">
                    {shapeFile?.name}
                  </p>
                </div>
              )}
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={hanleOpenSelectFile}
                >
                  Chọn file
                </Button>
                <input
                  id="shapeFile"
                  inputRef={register({ required: true })}
                  type="file"
                  name="shapeFile"
                  accept=".zip, .rar"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                  className="required"
                  required
                />
                {errors.shapeFile && errors.shapeFile.type === "required" && (
                  <p className="error">Trường này là bắt buộc</p>
                )}
              </div>
            </div> */}
          </DialogContent>

          <DialogActions className="border-top">
            <Button
              type="submit"
              onClick={onClose}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              startIcon={<SaveIcon />}
            >
              Lưu
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      {isShowZip && (
        <Dialog
          onClose={onCloseSelectZipFile}
          open={isShowZip}
          fullWidth={true}
          maxWidth="md"
          className="dialog-preview-form"
        >
          <DialogTitle disableTypography>
            <Typography variant="h6">Quản lý file</Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onCloseSelectZipFile}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <FileManagement
              files={zipFiles}
              setFiles={setZipFiles}
              acceptedFiles={["zip"]}
              isShowLeft={false}
              isShowAddFolder={false}
              isShowDownload={true}
              // typeFile={'ZIP'}
              //cgisId={cgisId}
            />
          </DialogContent>

          <DialogActions>
            <Button
              type="button"
              onClick={onCloseSelectZipFile}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            {zipFiles && zipFiles.length > 0 && zipFiles !== zipFilesTemp && (
              <Button
                type="button"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={onSaveSelectZipFile}
              >
                Lưu
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
