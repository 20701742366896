import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Configs } from "../../../common/config";

//--- Material Table
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";

//--- Material Icon
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Badge from "@material-ui/core/Badge";

//--- Material Control
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { UrlCollection } from "../../../common/url-collection";
import history from "../../../common/history";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  tableContainer: {
    maxHeight: window.outerHeight - 365,
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const headCells = [
  { id: "fileCode", hideSortIcon: false, label: "Mã dự án" },
  { id: "projectName", hideSortIcon: false, label: "Tên dự án" },
  { id: "address", hideSortIcon: false, label: "Địa chỉ" },
  { id: "districtName", hideSortIcon: false, label: "Quận huyện" },
  { id: "projectKindName", hideSortIcon: false, label: "Loại dự án" },
  { id: "projectTypeName", hideSortIcon: false, label: "Chi tiết loại dự án" },
  { id: "investorName", hideSortIcon: false, label: "Chủ đầu tư" },
  { id: "areaSize", hideSortIcon: false, label: "Quy mô (ha)" },
  { id: "totalInvestment", hideSortIcon: false, label: "Tổng mức đầu tư" },
  { id: "executionTime", hideSortIcon: false, label: "Thời gian thực hiện" },
  { id: "constructionProgress", hideSortIcon: false, label: "Tiến độ thực hiện" },
  { id: "planningName", hideSortIcon: false, label: "Quy hoạch được phê duyệt" },
  { id: "numberApproval", hideSortIcon: false, label: "Số quyết định phê duyệt" },
  { id: "dateApproval", hideSortIcon: false, label: "Ngày ra quyết định phê duyệt" },
  { id: "establishConsultingUnit", hideSortIcon: false, label: "Đơn vị tư vấn lập" },
  { id: "approvalUnit", hideSortIcon: false, label: "Đơn vị ra quyết định phê duyệt" },
  { id: "averageSalesPrice", hideSortIcon: false, label: "Giá bán bình quân" },
  { id: "averageRentalPrice", hideSortIcon: false, label: "Giá thuê bình quân" },
  { id: "businessStatusName", hideSortIcon: false, label: "Trạng thái" },
  { id: "note", hideSortIcon: false, label: "Ghi chú" },
  { id: "legalDocument", hideSortIcon: false, label: "Danh sách tài liệu" },
  { id: "actions", hideSortIcon: true, label: "" },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            className="pt-3 pb-3 text-nowrap"
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              hideSortIcon={headCell.hideSortIcon ? true : false}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function ListRealEstateProjectManagement(props) {
  const {
    editAction,
    RealEstateProjectModels,
    totalItemCount,
    setOrder,
    setOrderBy,
    setPage,
    setRowsPerPage,
    GetListRealEstateProject,
    name,
    order,
    page,
    rowsPerPage,
    orderBy,
    deleteAction,
  } = props;

  //--- Config table
  const classes = useStyles();

  //--- Handle sort, change page, change row per page
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    let sort = isAsc ? "desc" : "asc";
    let sortExpression = property + " " + sort;
    GetListRealEstateProject(page + 1, rowsPerPage, sortExpression, name);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    let sortExpression = orderBy + " " + order;
    GetListRealEstateProject(newPage + 1, rowsPerPage, sortExpression, name);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    let sortExpression = orderBy + " " + order;
    GetListRealEstateProject(1, event.target.value, sortExpression, name);
  };
  const goLegalDocumentView = (projectId, Type) => {
    history.push(`${UrlCollection.ListLegalDocumentManagement}?projectId=${projectId}&type=${Type}`);
  };
  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, RealEstateProjectModels.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table} size="small" stickyHeader>
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={RealEstateProjectModels.length}
            />
            <TableBody>
              {RealEstateProjectModels && RealEstateProjectModels.length > 0 ? (
                RealEstateProjectModels.map((row, index) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.id}>
                      <TableCell>{row.fileCode}</TableCell>
                      <TableCell>{row.projectName}</TableCell>
                      <TableCell>{row.address}</TableCell>
                      <TableCell>{row.districtName}</TableCell>
                      <TableCell>{row.projectKindName}</TableCell>
                      <TableCell>{row.projectTypeName}</TableCell>
                      <TableCell>{row.investorName}</TableCell>
                      <TableCell>{row.areaSize}</TableCell>
                      <TableCell>{row.totalInvestment}</TableCell>
                      <TableCell>{row.executionTime}</TableCell>
                      <TableCell>{row.constructionProgress}</TableCell>
                      <TableCell>{row.planningName}</TableCell>
                      <TableCell>{row.numberApproval}</TableCell>
                      <TableCell>{row.dateApproval}</TableCell>
                      <TableCell>{row.establishConsultingUnit}</TableCell>
                      <TableCell>{row.approvalUnit}</TableCell>
                      <TableCell>{row.note}</TableCell>
                      <TableCell>{row.averageSalesPrice}</TableCell>
                      <TableCell>{row.averageRentalPrice}</TableCell>
                      <TableCell>{row.businessStatusName}</TableCell>
                      <TableCell align="center">
                        <Tooltip title="Danh sách tài liệu">
                          <IconButton
                            onClick={() =>
                              goLegalDocumentView(row.id, "real_estate_project")
                            }
                          >
                            <Badge
                              color="primary"
                              badgeContent={row.legalDocumentsCount}
                              showZero
                            >
                              <AssignmentIcon />
                            </Badge>
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="right" className="text-nowrap">
                        <Tooltip title="Sửa">
                          <IconButton
                            aria-label="edit"
                            onClick={() => editAction(row.id)}
                          >
                            <EditIcon className="text-primary" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Xóa">
                          <IconButton
                            aria-label="delete"
                            onClick={() => deleteAction(row.id)}
                          >
                            <DeleteIcon className="text-danger" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow hover tabIndex={-1}>
                  <TableCell colSpan={9} className="text-center">
                    Không có dữ liệu
                  </TableCell>
                </TableRow>
              )}
              {emptyRows > 0 && (
                <TableRow style={{ height: 0 }}>
                  <TableCell
                    colSpan={4}
                    style={{ padding: 0, borderBottom: 0 }}
                  />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {totalItemCount && totalItemCount > 0 ? (
          <TablePagination
            rowsPerPageOptions={Configs.DefaultPageSizeOption}
            component="div"
            count={totalItemCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage={"Số hàng mỗi trang"}
            labelDisplayedRows={({ from, to, count }) => {
              return "" + from + "-" + to + " trong " + count || "";
            }}
          />
        ) : (
          ""
        )}
      </Paper>
    </div>
  );
}
