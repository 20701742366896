import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

//--- Material Control
import {
  DialogActions,
  Button,
  TextField,
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
  IconButton,
  makeStyles,
} from "@material-ui/core";

//--- Material Icon
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import {
  NotificationMessageType,
  changeAlias,
} from "../../../utils/configuration";
import { useLocation,useHistory } from "react-router-dom";
import * as viVN from "../../../language/vi-VN.json";
import FileManagement from "../../../components/file_management/file_management";

//--- Action
import * as action from "../../../redux/store/legal-document-management/legal-document-management.store";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formControl: {
    margin: theme.spacing(1),
  },
}));

export default function AddLegalDocumentManagement(props) {
  const classes = useStyles();
  const [files, setFiles] = useState([]);
  const [filesTemp, setFilesTemp] = useState([]);
  const [legalDocumentList, setLegalDocumentList] = useState([]);
  const location = useLocation();
  const paramsQuery = new URLSearchParams(location.search);
  const projectId =  paramsQuery.get("projectId");
  const type =  paramsQuery.get("type");

  const [isShow, setShow] = useState(false);
  //File
  const onOpenSelectFile = () => {
    setShow(true);
    setFilesTemp(files);
  };

  const onCloseSelectFile = () => {
    setShow(false);
    setFiles(filesTemp);
  };

  const onSaveSelectFile = () => {
    console.log(files);
    setShow(false);
  };

  const {
    isOpen,
    onClose,
    onSuccess,
    GetListLegalDocument,
    rowsPerPage,
    showLoading,
  } = props;
  const { register, handleSubmit, errors, setValue, clearErrors, setError } =
    useForm({
      mode: "all",
      reValidateMode: "onBlur",
    });
  const onSubmit = (data) => {
    if (!data) {
      return;
    } else {
      let formData = new FormData();
      formData.append("name", data?.name);
      formData.append("summary", data?.summary);
      formData.append("publishedDate", data?.publishedDate);
      if (files && files.length > 0)
        formData.append("documentUploadId", files[0].fileId);
      if(type == "housing_project"){
        formData.append("projectId", projectId || 0);
        formData.append("type", "housing_project");
      }
      if(type == "construction_real_estate")
      {
        formData.append("projectId", projectId || 0);
        formData.append("type", "construction_real_estate");
      }
      if(type == "real_estate_project"){
        formData.append("projectId", projectId || 0);
        formData.append("type", "real_estate_project");
      }

      //showLoading(true);
      action
        .Create(formData)
        .then((result) => {
          if (result && result.content && result.content.status === true) {
            GetListLegalDocument(1, rowsPerPage);
            showLoading(false);
            onSuccess();
            ShowNotification(
              viVN.Success.CreateSuccess,
              NotificationMessageType.Success
            );
          }
        })
        .catch((err) => {
          onSuccess();
          showLoading(false);
          ShowNotification(
            viVN.Errors[err.errorType],
            NotificationMessageType.Error
          );
        });
    }
  };

  return (
    <div>
      <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="md">
        <DialogTitle disableTypography className="border-bottom">
          <Typography variant="h6">Thêm văn bản pháp lý</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent className="pt-4 pb-2" dividers>
            <div className="form-group row">
              <div className="col-6">
                <label className="text-dark">Tên văn bản pháp lý</label>
                <span className="required"></span>

                <TextField
                  fullWidth
                  type="text"
                  name="name"
                  className="w-100"
                  inputRef={register({ required: true, maxLength: 300 })}
                  variant="outlined"
                  size="small"
                  error={
                    (errors.name && errors.name.type === "required") ||
                    (errors.name && errors.name.type === "maxLength")
                  }
                />
                {errors.name && errors.name.type === "required" && (
                  <span className="error">Trường này là bắt buộc</span>
                )}
                {errors.name && errors.name.type === "maxLength" && (
                  <span className="error">Tối đa 300 ký tự</span>
                )}
              </div>
              <div className="col-6">
                <label className="text-dark">Ngày xuất bản</label>
                <TextField
                  fullWidth
                  type="date"
                  name="publishedDate"
                  className="w-100"
                  inputRef={register}
                  variant="outlined"
                  size="small"
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-12">
                <label className="text-dark">Bản tóm tắt</label>
                <textarea
                  name="summary"
                  rows="5"
                  className="form-control"
                  ref={register}
                ></textarea>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-12">
                <label className="text-dark">
                  Tệp tài liệu<span className="required"></span>
                </label>
                {!isShow &&
                  files &&
                  files.length > 0 &&
                  files.map((item) => (
                    <div key={item.fileName} style={{ width: "150px" }}>
                      <img
                        src={require("../../../assets/icon/default.svg")}
                        alt={item.fileName}
                        className="img-fluid mb-2"
                        style={{
                          width: "auto",
                          height: "auto",
                          maxWidth: "100%",
                          maxHeight: "100%",
                        }}
                      />
                      <p className="file_name">{item.fileName}</p>
                    </div>
                  ))}
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onOpenSelectFile}
                  >
                    Chọn file
                  </Button>
                  <TextField
                    inputRef={register({ required: true })}
                    type="hidden"
                    name="image"
                    value={
                      (files && files.length > 0 && files[0].fileName) || ""
                    }
                  />
                  {errors.image && errors.image.type === "required" && (
                    <p className="error">Trường này là bắt buộc</p>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group row"></div>
          </DialogContent>
          <DialogActions className="border-top">
            <Button
              type="button"
              onClick={onClose}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              startIcon={<SaveIcon />}
            >
              Lưu
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      {isShow && (
        <Dialog
          onClose={onCloseSelectFile}
          open={isShow}
          fullWidth={true}
          maxWidth="md"
          className="dialog-preview-form"
        >
          <DialogTitle disableTypography>
            <Typography variant="h6">Quản lý file</Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onCloseSelectFile}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <FileManagement
              files={files}
              setFiles={setFiles}
              acceptedFiles={["doc", "docx", "txt", "pdf", "png", "jpg"]}
            />
          </DialogContent>

          <DialogActions>
            <Button
              type="button"
              onClick={onCloseSelectFile}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            {files && files.length > 0 && (
              <Button
                type="button"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={onSaveSelectFile}
              >
                Lưu
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
