import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";
import * as config from "../../../common/config"

const service = new Service();

export const GetListAll = (pageIndex = 1, pageSize = config.Configs.DefaultPageSize, sortExpression = "modifiedDate desc",title="") => {
    const params = new URLSearchParams();
    params.append('pageIndex',pageIndex);
    params.append('pageSize',pageSize);
    params.append('sorting',sortExpression);
    title && params.append('Keyword',title);
    return service.get(ApiUrl.ConstructionCategory_GetListAll,params).then(res => { return res }).catch(err => { throw err });
}
export const GetLookUpConstructionCategory = () => {
    return service.get(ApiUrl.ConstructionCategory_GetLookUp).then(res => { return res }).catch(err => { throw err });
}
export const GetDetail = (id) => {
    const params = new URLSearchParams();
    params.append("id", id);
    return service.get(ApiUrl.ConstructionCategory_GetDetail.replace("{id}",id)).then(res => { return res }).catch(err => { throw err });
}

export const Create = (body) => {
    return service.post(ApiUrl.ConstructionCategory_Create, body).then(res => { return res }).catch(err => { throw err });
}

export const Update = (body) => {
    return service.post(ApiUrl.ConstructionCategory_Update, body).then(res => { return res }).catch(err => { throw err });
}

export const Delete = (id) => {
    return service.postParams(ApiUrl.ConstructionCategory_Delete + "/" + id ).then(res => { return res }).catch(err => { throw err });
}