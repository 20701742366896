/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Autocomplete from "@material-ui/lab/Autocomplete";
//--- Action
import * as projectTypeAction from "../../../redux/store/project-type/project-type.store";
//--- Material Control
import {
  DialogActions,
  Button,
  TextField,
  DialogContent,
  DialogTitle,
  Dialog,
  makeStyles,
  Typography,
  IconButton,
} from "@material-ui/core";

//--- Material Icon
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";

//--- Notifications
import ShowNotification from "../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../utils/configuration";
import * as viVN from "../../../language/vi-VN.json";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export default function EditProjectTypeManagement(props) {
  const classes = useStyles();

  const {
    isOpen,
    onClose,
    onSuccess,
    projectTypeId,
    GetListProjectType,
    rowsPerPage,
    showLoading,
    projectTypeModels,
    projectKindModels,
  } = props;
  const [projectKindId, setProjectKindId] = useState();
  const [projectType, setProjectType] = useState();

  const { register, handleSubmit, errors } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  useEffect(() => {
    showLoading(true);
    projectTypeAction
      .GetDetail(projectTypeId)
      .then((res) => {
        if (res && res.content) {
          setProjectType(res.content);
          setProjectKindId(res.content.projectKindId);
        }
        showLoading(false);
      })
      .catch((err) => {
        showLoading(false);
      });
  }, []);

  const onSubmit = (data) => {
    if (!data) {
      return;
    }
    projectTypeAction
      .Update({
        ...data,
        id: projectTypeId,
        projectKindId: projectKindId,
      })
      .then((result) => {
        if (result) {
          GetListProjectType(1, rowsPerPage);
          onSuccess();
          ShowNotification(
            viVN.Success.EditLandType,
            NotificationMessageType.Success
          );
        }
      })
      .catch((err) => {
        onSuccess();
        ShowNotification(
          viVN.Errors[err.errorType],
          NotificationMessageType.Error
        );
      });
  };

  const handleOnchangeProjectKind = (event, newValue) => {
    if (newValue) {
      setProjectKindId(newValue.id);
    }
  };

  return (
    <div>
      <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="md">
        <DialogTitle disableTypography className="border-bottom">
          <Typography variant="h6">Chỉnh sửa chi tiết loại dự án</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          {projectType && (
            <DialogContent className="pt-4 pb-2">
              <div className="form-group">
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-12">
                    <label className="text-dark">
                      Tên chi tiết loại dự án<span className="required"></span>
                    </label>
                    <TextField
                      type="text"
                      name="name"
                      defaultValue={projectType.name}
                      className="w-100"
                      inputRef={register({ required: true })}
                      error={errors.name && errors.name.type === "required"}
                    />
                    {errors.name && errors.name.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                  </div>
                  <div className="col-12 col-md-12 col-lg-12">
                    <label className="text-dark">
                      Mã màu<span className="required"></span>
                    </label>
                    <TextField
                      type="text"
                      name="color"
                      defaultValue={projectType.color}
                      className="w-100"
                      inputRef={register({ required: true })}
                      error={errors.color && errors.color.type === "required"}
                    />
                    {errors.color && errors.color.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-12">
                    {projectKindModels && projectKindModels.length > 0 && (
                      <Autocomplete
                        id="projectKind"
                        options={projectKindModels}
                        defaultValue={{
                          id: projectType.projectKindId,
                          name: projectType.nameProjectKind,
                        }}
                        getOptionLabel={(option) => option.name}
                        fullWidth
                        onChange={(event, newValue) =>
                          handleOnchangeProjectKind(event, newValue)
                        }
                        disableClearable={true}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Chọn loại dự án (*)"
                            name={`projectKindId`}
                            inputRef={register({ required: true })}
                            size="small"
                            variant="outlined"
                            error={
                              errors[`projectKindId`] &&
                              errors[`projectKindId`].type === "required"
                            }
                          />
                        )}
                      />
                    )}
                  </div>
                </div>
              </div>
            </DialogContent>
          )}
          <DialogActions className="border-top">
            <Button
              type="submit"
              onClick={onClose}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              startIcon={<SaveIcon />}
            >
              Lưu
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
