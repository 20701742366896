import React, { useEffect, useState } from "react";

import {
  DialogActions,
  Button,
  TextField,
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
  IconButton,
  makeStyles,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
//action

import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import * as viVN from "../../../language/vi-VN.json";
import { NotificationMessageType } from "../../../utils/configuration";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as action from "../../../redux/store/real-estate-project/real-estate-project.store";
import * as helper from "../../../utils/helper.js";
import FileManagement from "../../../components/file_management/file_management";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export default function EditRealEstateProjectManagement(props) {
  const {
    isOpen,
    onClose,
    onSuccess,
    GetListRealEstateProject,
    rowsPerPage,
    showLoading,
    districtModels,
    realEstateProjectId,
    openCreateByShapeFile,
  } = props;
  const { register, handleSubmit, errors, setValue, clearErrors, setError } =
    useForm({
      mode: "all",
      reValidateMode: "onBlur",
    });
  const classes = useStyles();
  const [isImageShow, setImageShow] = useState(false);
  const [files, setFiles] = useState([]);
  const [filesTemp, setFilesTemp] = useState([]);
  const [lookupDistrictModel, setLookupDistrictModel] = useState([]);
  const [districtId, setDistrictId] = useState();
  const [lookupAreaTypeModel, setLookupAreaTypeModel] = useState([]);
  const [areaTypeId, setAreaTypeId] = useState();
  const [lookupProjectTypeModel, setLookupProjectTypeModel] = useState([]);
  const [projectTypeId, setProjectTypeId] = useState();
  const [lookupProjectKindModel, setLookupProjectKindModel] = useState([]);
  const [projectKindId, setProjectKindId] = useState([]);
  const [businessStatusId, setBusinessStatusId] = useState([]);
  const [lookupInvestorModel, setLookupInvestorModel] = useState([]);
  const [investorId, setInvestorId] = useState([]);
  const [legalDocumentsId, setLegalDocumentsId] = useState([]);
  const [lookupPlanningModel, setLookupPlanningModel] = useState([]);
  const [planningId, setPlanningId] = useState([]);
  const [lookupBusinessStatusModel, setLookupBusinessStatusModel] = useState(
    []
  );
  const [lookupLegalDocumentsModel, setLookupLegalDocumentsModel] = useState(
    []
  );
  const [realEstateProject, setRealEstateProject] = useState();
 //File
 const [zipFiles, setZipFiles] = useState([]);
 const [zipFilesTemp, setZipFilesTemp] = useState([]);
 const [isShowZip, setShowZip] = useState(false);
 const [zipName, setZipName] = useState(null);
  useEffect(() => {
    showLoading(true);
    onGetData();
    action
      .GetDetail(realEstateProjectId)
      .then((res) => {
        if (res && res.content) {
          setRealEstateProject(res.content);
          setDistrictId(res.content.districtId);
          setProjectKindId(res.content.projectKindId);
          setProjectTypeId(res.content.projectTypeId);
          setInvestorId(res.content.investorId);
          setPlanningId(res.content.planningId);
          setLegalDocumentsId(res.content.legalDocumentsId);
          setBusinessStatusId(res.content.businessStatusId);
          setZipFiles(res.content.shapeFileId ? [res.content.shapeFileId] : []);
        }
        showLoading(false);
      })
      .catch((err) => {
        showLoading(false);
      });
  }, []);
  const onGetData = () => {
    showLoading(true);
    Promise.all([
      helper.LookupDistrict(),
      helper.LookupProjectKind(),
      helper.LookupProjectType(),
      helper.LookupInvestor(),
      helper.LookupPlanning(),
      helper.LookupBusinessStatus(),
      helper.LookupLegalDocument(),
    ])
      .then((res) => {
        const [
          lookupDistrict,
          lookupProjectKind,
          lookupProjectType,
          lookupInvestor,
          lookupPlanningModel,
          lookupBusinessStatus,
          lookupLegalDocument,
        ] = res;
        lookupDistrict &&
          lookupDistrict.content &&
          setLookupDistrictModel(lookupDistrict.content);
        lookupProjectKind &&
          lookupProjectKind.content &&
          setLookupProjectKindModel(lookupProjectKind.content);
        lookupProjectType &&
          lookupProjectType.content &&
          setLookupProjectTypeModel(lookupProjectType.content);
        lookupInvestor &&
          lookupInvestor.content &&
          setLookupInvestorModel(lookupInvestor.content);
        lookupPlanningModel &&
          lookupPlanningModel.content &&
          setLookupPlanningModel(lookupPlanningModel.content);
        lookupBusinessStatus &&
          lookupBusinessStatus.content &&
          setLookupBusinessStatusModel(lookupBusinessStatus.content);
        lookupLegalDocument &&
          lookupLegalDocument.content &&
          setLookupLegalDocumentsModel(lookupLegalDocument.content);
        showLoading(false);
      })
      .catch((err) => {
        showLoading(false);
      });
  };
  const onSubmit = (data) => {
    if (!data) {
      return;
    } else {
      let formData = new FormData();
      formData.append("id", realEstateProjectId);
      formData.append("fileCode", realEstateProject.fileCode);
      formData.append("projectName", data?.projectName);
      formData.append("address", data?.address);
      formData.append("districtId", districtId || data?.districtId);
      formData.append("projectKindId", projectKindId || data?.projectKindId);
      formData.append("projectTypeId", projectTypeId || data?.projectTypeId);
      formData.append("investorId", investorId || data?.investorId);
      formData.append("areaSize", data?.areaSize);
      formData.append("totalInvestment", data?.totalInvestment);
      formData.append("executionTime", data?.executionTime);
      formData.append("constructionProgress", data?.constructionProgress);
      formData.append("planningId", planningId || data?.planningId);
      formData.append("numberApproval", data?.numberApproval);
      formData.append("dateApproval", data?.dateApproval);
      formData.append("establishConsultingUnit", data?.establishConsultingUnit);
      formData.append("approvalUnit", data?.approvalUnit);
      formData.append("note", data?.note);
      formData.append("averageSalesPrice", data?.averageSalesPrice);
      formData.append("averageRentalPrice", data?.averageRentalPrice);
      //formData.append("legalDocumentsId", JSON.stringify(legalDocumentsId));
      legalDocumentsId.forEach((id, index) => {
        formData.append(`legalDocumentsId[${index}]`, id);
      });
      formData.append(
        "businessStatusId",
        businessStatusId || data?.businessStatusId
      );
      if (zipFiles && zipFiles.length > 0) {
        formData.append("shapeFileId", zipFiles[0]?.fileId || zipFiles[0]);
      }

      action
        .Update(formData)
        .then((result) => {
          if (result && result.content && result.content.status === true) {
            GetListRealEstateProject(1, rowsPerPage);
            showLoading(false);
            onSuccess();
            ShowNotification(
              viVN.Success.UpdateSuccess,
              NotificationMessageType.Success
            );
          }
        })
        .catch((err) => {
          showLoading(false);
          onSuccess();
          ShowNotification(
            viVN.Errors[err.errorType],
            NotificationMessageType.Error
          );
        });
    }
  };

  const onOpenSelectFile = () => {
    setImageShow(true);
    setFilesTemp(files);
  };

  const onCloseSelectFile = () => {
    setImageShow(false);
    setFiles(filesTemp);
  };

  const onSaveSelectFile = () => {
    setImageShow(false);
  };
  const handleOnchangeDistrict = (event, newValue) => {
    if (newValue) {
      setDistrictId(newValue.id);
    }
  };
  const handleOnchangeProjectKind = (event, newValue) => {
    if (newValue) {
      setProjectKindId(newValue.id);
    }
  };
  const handleOnchangeProjectType = (event, newValue) => {
    if (newValue) {
      setProjectTypeId(newValue.id);
    }
  };
  const handleOnchangeInvestor = (event, newValue) => {
    if (newValue) {
      setInvestorId(newValue.id);
    }
  };
  const handleOnchangePlanning = (event, newValue) => {
    if (newValue) {
      setPlanningId(newValue.id);
    }
  };
  const handleOnchangeLegalDocument = (event, newValue) => {
    setLegalDocumentsId(newValue.map((option) => option.id));
  };
  const handleOnchangeBusinessStatus = (event, newValue) => {
    if (newValue) {
      setBusinessStatusId(newValue.id);
    }
  };

  // const [shapeFile, setShapeFile] = useState(null);

  // const hanleOpenSelectFile = () => {
  //   document.getElementById("shapeFile").click();
  // };

  // const handleFileChange = (event) => {
  //   clearErrors("shapeFile");
  //   const selectedFile = event.target.files[0];

  //   if (!selectedFile) {
  //     setShapeFile(null);
  //     return;
  //   }
  //   setValue("shapeFile", selectedFile);
  //   if (
  //     selectedFile &&
  //     (selectedFile.type === "application/zip" ||
  //       selectedFile.type === "application/x-zip-compressed" ||
  //       selectedFile.type === "application/x-compressed")
  //   ) {
  //     setShapeFile(selectedFile);
  //   } else {
  //     setShapeFile(null);
  //     ShowNotification(
  //       "Shape file không phù hợp.",
  //       NotificationMessageType.Error
  //     );
  //   }
  // };
  const onOpenSelectZipFile = () => {
    setShowZip(true);
    setZipFilesTemp(zipFiles);
    setZipName(null);
  };

  const onCloseSelectZipFile = () => {
    setShowZip(false);
    setZipFiles(zipFilesTemp);
  };

  const onSaveSelectZipFile = () => {
    setShowZip(false);
    setZipName(zipFiles[0].fileName);
  };
  return (
    <div>
      <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="md">
        <DialogTitle disableTypography className="border-bottom">
          <Typography variant="h6">Sửa dự án BDS</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          {realEstateProject && (
            <DialogContent className="pt-4 pb-2">
              <div className="form-group row">
                <div className="col-6">
                  <label className="text-dark">
                    Mã hồ sơ<span className="required"></span>
                  </label>
                  <TextField
                    fullWidth
                    disabled
                    type="text"
                    name="fileCode"
                    className="w-100"
                    defaultValue={realEstateProject.fileCode}
                    inputRef={register({
                      required: true,
                      // validate: handleCheckCodeExist,
                    })}
                    id="fileCode"
                    error={
                      (errors.fileCode &&
                        errors.fileCode.type === "required") ||
                      (errors.fileCode && errors.fileCode.type === "validate")
                    }
                    variant="outlined"
                    size="small"
                  />
                  {errors.fileCode && errors.fileCode.type === "required" && (
                    <span className="error">Trường này là bắt buộc</span>
                  )}
                </div>

                <div className="col-6">
                  <label className="text-dark">
                    Tên dự án<span className="required"></span>
                  </label>

                  <TextField
                    fullWidth
                    inputRef={register({ required: true })}
                    type="text"
                    name="projectName"
                    defaultValue={realEstateProject.projectName}
                    error={
                      errors.projectName &&
                      errors.projectName.type === "required"
                    }
                    variant="outlined"
                    size="small"
                  />
                  {errors.projectName &&
                    errors.projectName.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                </div>
              </div>
              <div className="form-group row">
                <div className="col-6">
                  <label className="text-dark">Địa chỉ</label>

                  <TextField
                    fullWidth
                    type="text"
                    name="address"
                    defaultValue={realEstateProject.address}
                    className="w-100"
                    inputRef={register}
                    variant="outlined"
                    size="small"
                  />
                </div>

                {lookupDistrictModel && lookupDistrictModel.length > 0 && (
                  <div className="col-6">
                    <label className="text-dark">Quận huyện</label>
                    <Autocomplete
                      options={lookupDistrictModel}
                      getOptionLabel={(option) =>
                        typeof option === "string" ? option : option.name
                      }
                      onChange={(event, newValue) =>
                        handleOnchangeDistrict(event, newValue)
                      }
                      defaultValue={{
                        id: realEstateProject.districtId,
                        name: realEstateProject.districtName,
                      }}
                      renderInput={(params) => (
                        <TextField
                          name={`districtId`}
                          inputRef={register}
                          {...params}
                          variant="outlined"
                          size="small"
                        />
                      )}
                    />
                  </div>
                )}
              </div>

              <div className="form-group row">
                {lookupProjectKindModel &&
                  lookupProjectKindModel.length > 0 && (
                    <div className="col-6 col-md-6 col-lg-6">
                      <label className="text-dark">Loại dự án</label>
                      <Autocomplete
                        options={lookupProjectKindModel}
                        getOptionLabel={(option) =>
                          typeof option === "string" ? option : option.name
                        }
                        onChange={(event, newValue) =>
                          handleOnchangeProjectKind(event, newValue)
                        }
                        defaultValue={{
                          id: realEstateProject.projectKindId,
                          name: realEstateProject.projectKindName,
                        }}
                        renderInput={(params) => (
                          <TextField
                            name={`projectKindId`}
                            inputRef={register}
                            {...params}
                            variant="outlined"
                            size="small"
                          />
                        )}
                      />
                    </div>
                  )}
                {lookupProjectTypeModel &&
                  lookupProjectTypeModel.length > 0 && (
                    <div className="col-6 col-md-6 col-lg-6">
                      <label className="text-dark">Chi tiết loại dự án</label>
                      <Autocomplete
                        options={lookupProjectTypeModel}
                        getOptionLabel={(option) =>
                          typeof option === "string" ? option : option.name
                        }
                        onChange={(event, newValue) =>
                          handleOnchangeProjectType(event, newValue)
                        }
                        defaultValue={{
                          id: realEstateProject.projectTypeId,
                          name: realEstateProject.projectTypeName,
                        }}
                        renderInput={(params) => (
                          <TextField
                            name={`projectTypeId`}
                            defaultValue={realEstateProject.fileCode}
                            inputRef={register}
                            {...params}
                            variant="outlined"
                            size="small"
                          />
                        )}
                      />
                    </div>
                  )}
              </div>

              <div className="form-group row">
                {lookupInvestorModel && lookupInvestorModel.length > 0 && (
                  <div className="col-6">
                    <label className="text-dark">Chủ đầu tư</label>
                    <Autocomplete
                      options={lookupInvestorModel}
                      getOptionLabel={(option) =>
                        typeof option === "string" ? option : option.name
                      }
                      onChange={(event, newValue) =>
                        handleOnchangeInvestor(event, newValue)
                      }
                      defaultValue={{
                        id: realEstateProject.investorId,
                        name: realEstateProject.investorName,
                      }}
                      renderInput={(params) => (
                        <TextField
                          name={`investorId`}
                          inputRef={register}
                          defaultValue={realEstateProject.fileCode}
                          {...params}
                          variant="outlined"
                          size="small"
                        />
                      )}
                    />
                  </div>
                )}
                <div className="col-6">
                  <label className="text-dark">Quy mô (ha)</label>

                  <TextField
                    fullWidth
                    type="number"
                    name="areaSize"
                    className="w-100"
                    defaultValue={realEstateProject.areaSize}
                    inputRef={register}
                    variant="outlined"
                    size="small"
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-6">
                  <label className="text-dark">Tổng mức đầu tư</label>

                  <TextField
                    fullWidth
                    inputRef={register}
                    type="text"
                    name="totalInvestment"
                    defaultValue={realEstateProject.totalInvestment}
                    error={errors.totalInvestment}
                    variant="outlined"
                    size="small"
                  />
                </div>
                <div className="col-6">
                  <label className="text-dark">Thời gian thực hiện</label>
                  <TextField
                    fullWidth
                    type="date"
                    name="executionTime"
                    defaultValue={
                      realEstateProject.executionTime
                        ? new Date(realEstateProject.executionTime)
                            .toISOString()
                            .split("T")[0]
                        : ""
                    }
                    className="w-100"
                    inputRef={register}
                    variant="outlined"
                    size="small"
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-6">
                  <label className="text-dark">Tiến độ thực hiện</label>

                  <TextField
                    fullWidth
                    inputRef={register}
                    type="text"
                    defaultValue={realEstateProject.constructionProgress}
                    name="constructionProgress"
                    variant="outlined"
                    size="small"
                  />
                </div>
                {lookupPlanningModel && lookupPlanningModel.length > 0 && (
                  <div className="col-6">
                    <label className="text-dark">Phê duyệt quy hoạch</label>
                    <Autocomplete
                      options={lookupPlanningModel}
                      getOptionLabel={(option) =>
                        typeof option === "string" ? option : option.name
                      }
                      defaultValue={{
                        id: realEstateProject.planningId,
                        name: realEstateProject.planningName,
                      }}
                      onChange={(event, newValue) =>
                        handleOnchangePlanning(event, newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          name={`planningId`}
                          defaultValue={realEstateProject.fileCode}
                          {...params}
                          variant="outlined"
                          inputRef={register}
                          size="small"
                        />
                      )}
                    />
                  </div>
                )}
              </div>
              <div className="form-group row">
                <div className="col-6">
                  <label className="text-dark">Số quyết định phê duyệt</label>

                  <TextField
                    fullWidth
                    inputRef={register}
                    defaultValue={realEstateProject.numberApproval}
                    type="number"
                    name="numberApproval"
                    variant="outlined"
                    size="small"
                  />
                </div>
                <div className="col-6">
                  <label className="text-dark">
                    Ngày ra quyết định phê duyệt
                  </label>
                  <TextField
                    fullWidth
                    type="date"
                    name="dateApproval"
                    defaultValue={
                      realEstateProject.dateApproval
                        ? new Date(realEstateProject.dateApproval)
                            .toISOString()
                            .split("T")[0]
                        : ""
                    }
                    className="w-100"
                    inputRef={register}
                    variant="outlined"
                    size="small"
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-6">
                  <label className="text-dark">Đơn vị tư vấn lập</label>

                  <TextField
                    fullWidth
                    inputRef={register}
                    defaultValue={realEstateProject.establishConsultingUnit}
                    type="text"
                    name="establishConsultingUnit"
                    variant="outlined"
                    size="small"
                  />
                </div>
                <div className="col-6">
                  <label className="text-dark">
                    Đơn vị ra quyết định phê duyệt
                  </label>

                  <TextField
                    fullWidth
                    type="text"
                    name="approvalUnit"
                    defaultValue={realEstateProject.approvalUnit}
                    className="w-100"
                    inputRef={register}
                    variant="outlined"
                    size="small"
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-6">
                  <label className="text-dark">Giá bán bình quân</label>

                  <TextField
                    fullWidth
                    type="number"
                    defaultValue={realEstateProject.averageSalesPrice}
                    name="averageSalesPrice"
                    className="w-100"
                    inputRef={register}
                    variant="outlined"
                    size="small"
                  />
                </div>
                <div className="col-6">
                  <label className="text-dark">Giá thuê bình quân</label>

                  <TextField
                    fullWidth
                    inputRef={register}
                    type="number"
                    defaultValue={realEstateProject.averageRentalPrice}
                    name="averageRentalPrice"
                    variant="outlined"
                    size="small"
                  />
                </div>
              </div>
              <div className="form-group row">
                {lookupLegalDocumentsModel &&
                  lookupLegalDocumentsModel.length > 0 && (
                    <div className="col-6">
                      <label className="text-dark">
                        Danh sách văn bản pháp lý
                      </label>
                      <Autocomplete
                        multiple
                        options={lookupLegalDocumentsModel}
                        getOptionLabel={(option) =>
                          typeof option === "string" ? option : option.name
                        }
                        onChange={(event, newValue) =>
                          handleOnchangeLegalDocument(event, newValue)
                        }
                        value={lookupLegalDocumentsModel.filter((doc) =>
                          legalDocumentsId.includes(doc.id)
                        )}
                        renderInput={(params) => (
                          <TextField
                            name={`legalDocumentsId`}
                            inputRef={register}
                            {...params}
                            variant="outlined"
                            size="small"
                          />
                        )}
                      />
                    </div>
                  )}
                {lookupBusinessStatusModel &&
                  lookupBusinessStatusModel.length > 0 && (
                    <div className="col-6">
                      <label className="text-dark">Trạng thái</label>
                      <Autocomplete
                        options={lookupBusinessStatusModel}
                        defaultValue={{
                          id: realEstateProject.businessStatusId,
                          name: realEstateProject.businessStatusName,
                        }}
                        getOptionLabel={(option) =>
                          typeof option === "string" ? option : option.name
                        }
                        onChange={(event, newValue) =>
                          handleOnchangeBusinessStatus(event, newValue)
                        }
                        renderInput={(params) => (
                          <TextField
                            name={`businessStatusId`}
                            inputRef={register}
                            {...params}
                            variant="outlined"
                            size="small"
                          />
                        )}
                      />
                    </div>
                  )}
              </div>
              <div className="form-group row">
                <div className="col-12">
                  <label className="text-dark">Ghi chú</label>
                  <textarea
                    defaultValue={realEstateProject.note}
                    name="note"
                    rows="5"
                    className="form-control"
                    ref={register}
                  ></textarea>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-6">
                  <label className="text-dark">Shape File</label>
                  <TextField
                    fullWidth
                    type="text"
                    name="shpName"
                    value={zipName || realEstateProject?.shapeFileName?.fileName || ""}
                    placeholder="Hãy tải file zip lên."
                    disabled
                    variant="outlined"
                    size="small"
                  />
                  <div className="mt-2">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onOpenSelectZipFile}
                    >
                      Chọn file zip
                    </Button>
                  </div>
                </div>
              </div>
              {/* <div className="form-group">
                <label className="text-dark">Shape File</label>
                {shapeFile &&
                  shapeFile.type === "application/x-zip-compressed" && (
                    <div style={{ width: "150px" }}>
                      <img
                        src={require("../../../assets/images/zip-file-format.png")}
                        alt={shapeFile?.name || "Default"}
                        className="img-fluid mb-2"
                        style={{
                          width: "auto",
                          height: "auto",
                          maxWidth: "100%",
                          maxHeight: "100%",
                        }}
                      />
                      <p style={{ color: "black" }} className="error">
                        {shapeFile?.name}
                      </p>
                    </div>
                  )}

                {shapeFile && shapeFile.type === "application/x-compressed" && (
                  <div style={{ width: "150px" }}>
                    <img
                      src={require("../../../assets/images/rar-file-format.png")}
                      alt={shapeFile?.name || "Default"}
                      className="img-fluid mb-2"
                      style={{
                        width: "auto",
                        height: "auto",
                        maxWidth: "100%",
                        maxHeight: "100%",
                      }}
                    />
                    <p style={{ color: "black" }} className="error">
                      {shapeFile?.name}
                    </p>
                  </div>
                )}
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={hanleOpenSelectFile}
                  >
                    Chọn file
                  </Button>
                  <input
                    id="shapeFile"
                    inputRef={register}
                    type="file"
                    name="shapeFile"
                    accept=".zip, .rar"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </div>
              </div> */}
            </DialogContent>
          )}
          <DialogActions className="border-top">
            <Button
              type="submit"
              onClick={onClose}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              startIcon={<SaveIcon />}
            >
              Lưu
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      {isShowZip && (
        <Dialog
          onClose={onCloseSelectZipFile}
          open={isShowZip}
          fullWidth={true}
          maxWidth="md"
          className="dialog-preview-form"
        >
          <DialogTitle disableTypography>
            <Typography variant="h6">Quản lý file</Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onCloseSelectZipFile}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <FileManagement
              files={zipFiles}
              setFiles={setZipFiles}
              acceptedFiles={["zip"]}
              isShowLeft={false}
              isShowAddFolder={false}
              isShowDownload={true}
              // typeFile={'ZIP'}
              //cgisId={cgisId}
            />
          </DialogContent>

          <DialogActions>
            <Button
              type="button"
              onClick={onCloseSelectZipFile}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            {zipFiles && zipFiles.length > 0 && zipFiles !== zipFilesTemp && (
              <Button
                type="button"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={onSaveSelectZipFile}
              >
                Lưu
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
