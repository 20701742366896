import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

//--- Material Control
import {
  DialogActions,
  Button,
  TextField,
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
  IconButton,
  makeStyles,
  Checkbox,
} from "@material-ui/core";

//--- Material Icon
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import {
  NotificationMessageType,
  changeAlias,
} from "../../../utils/configuration";
import * as viVN from "../../../language/vi-VN.json";
import * as action from "../../../redux/store/construction-real-estate-management/construction-real-estate-management.store.js";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as helper from "../../../utils/helper.js";
import DateFnsUtils from "@date-io/date-fns";
import viLocale from "date-fns/locale/vi";
import dateformat from "dateformat";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import FileManagement from "../../../components/file_management/file_management";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formControl: {
    margin: theme.spacing(1),
  },
}));

export default function AddConstructionRealEstateManagement(props) {
  const classes = useStyles();
  const {
    isOpen,
    onClose,
    onSuccess,
    GetListConstructionRealEstate,
    rowsPerPage,
    showLoading,
    cgisId,
  } = props;
  const { register, handleSubmit, errors, setValue, clearErrors, setError } =
    useForm({
      mode: "all",
      reValidateMode: "onBlur",
    });

  const [lookupRealEstateProjectModel, setLookupRealEstateProjectModel] =
    useState([]);
  const [realEstateProjectId, setRealEstateProjectId] = useState();
  const [
    lookupConstructionRealEstateTypeModel,
    setLookupConstructionRealEstateTypeModel,
  ] = useState([]);
  const [constructionRealEstateTypeId, setConstructionRealEstateTypeId] =
    useState();
  const [legalDocumentsId, setLegalDocumentsId] = useState([]);
  const [lookupLegalDocumentsModel, setLookupLegalDocumentsModel] = useState(
    []
  );
  const [isExistFileCode, setIsExistFileCode] = useState(false);
  const fileCode = document.getElementById("fileCode");

  const [files, setFiles] = useState([]);
  const [filesTemp, setFilesTemp] = useState([]);
  const [zipFiles, setZipFiles] = useState([]);
  const [zipFilesTemp, setZipFilesTemp] = useState([]);
  const [isShowZip, setShowZip] = useState(false);
  const [zipName, setZipName] = useState(null);

  useEffect(() => {
    onGetData();
  }, []);

  const onGetData = () => {
    showLoading(true);
    Promise.all([
      helper.LookupRealEstateProject(),
      helper.LookupConstructionRealEstateType(),
      helper.LookupLegalDocument(),
    ])
      .then((res) => {
        const [
          lookupRealEstateProject,
          lookupConstructionRealEstateType,
          lookupLegalDocument,
        ] = res;
        lookupRealEstateProject &&
          lookupRealEstateProject.content &&
          setLookupRealEstateProjectModel(lookupRealEstateProject.content);

        lookupConstructionRealEstateType &&
          lookupConstructionRealEstateType.content &&
          setLookupConstructionRealEstateTypeModel(
            lookupConstructionRealEstateType.content
          );

        lookupLegalDocument &&
          lookupLegalDocument.content &&
          setLookupLegalDocumentsModel(lookupLegalDocument.content);
        showLoading(false);
      })
      .catch((err) => {
        showLoading(false);
      });
  };
  const onSubmit = (data) => {
    console.log("data", data);
    if (!data) {
      return;
    } else if (isExistFileCode) {
      if (fileCode) fileCode.focus();
      return;
    } else {
      let formData = new FormData();
      formData.append("fileCode", data?.fileCode);
      formData.append("constructionCode", data?.constructionCode);
      formData.append(
        "realEstateProjectId",
        realEstateProjectId || data?.realEstateProjectId
      );
      formData.append("projectName", data?.projectName);
      formData.append(
        "constructionRealEstateTypeId",
        constructionRealEstateTypeId || data?.constructionRealEstateTypeId
      );
      formData.append("numberFloor", data?.numberFloor);
      formData.append("numberHouse", data?.numberHouse);
      formData.append("totalArea", data?.totalArea);
      formData.append("averageSalesPrice", data?.averageSalesPrice);
      formData.append("averageRentalPrice", data?.averageRentalPrice);
      // legalDocumentsId.forEach((id, index) => {
      //   formData.append(`legalDocumentsId[${index}]`, id);
      // });
      //formData.append("shpFile", shapeFile);
      // zipFiles.forEach((zipFile, index) => {
      //   //  formData.append("shpId", data.shpFile);
      //   formData.append(`shapeFileId[${index}]`, zipFile.fileId);
      // });
      if (zipFiles && zipFiles.length > 0)
        formData.append("shapeFileId", zipFiles[0].fileId);
      action
        .Create(formData)
        .then((result) => {
          if (result && result.content && result.content.status === true) {
            GetListConstructionRealEstate(1, rowsPerPage);
            showLoading(false);
            onSuccess();
            ShowNotification(
              viVN.Success.CreateSuccess,
              NotificationMessageType.Success
            );
          }
        })
        .catch((err) => {
          showLoading(false);
          onSuccess();
          ShowNotification(
            viVN.Errors[err.errorType],
            NotificationMessageType.Error
          );
        });
    }
  };

  let timeout = 0;
  const handleCheckCodeExist = (event) => {
    let value = event;

    if (!value) {
      setError("fileCode", { type: "required" });
    } else {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        CheckExistedConstructionRealEstate(value);
      }, 100);
    }
  };
  const CheckExistedConstructionRealEstate = (fileCode) => {
    action
      .CheckExistedFileCode(fileCode)
      .then((res) => {
        if (res && res.content && res.content.status) {
          clearErrors("fileCode");
          setIsExistFileCode(false);
        } else {
          setError("fileCode", { type: "validate" });
          setIsExistFileCode(true);
        }
      })
      .catch((err) => {
        setError("fileCode", { type: "validate" });
        setIsExistFileCode(true);
      });
  };

  const handleOnchangeRealEstateProject = (event, newValue) => {
    if (newValue) {
      setRealEstateProjectId(newValue.id);
    }
  };
  const handleOnchangeContructionRealEstateType = (event, newValue) => {
    if (newValue) {
      setConstructionRealEstateTypeId(newValue.id);
    }
  };
  const handleOnchangeLegalDocument = (event, newValue) => {
    if (newValue) {
      const newIds = newValue.map((item) => item.id);
      setLegalDocumentsId(newIds);
    } else {
      setLegalDocumentsId([]);
    }
  };

  // const [shapeFile, setShapeFile] = useState(null);

  // const hanleOpenSelectFile = () => {
  //   document.getElementById("shapeFile").click();
  // };

  // const handleFileChange = (event) => {
  //   clearErrors("shapeFile");
  //   const selectedFile = event.target.files[0];

  //   if (!selectedFile) {
  //     setShapeFile(null);
  //     return;
  //   }
  //   setValue("shapeFile", selectedFile);
  //   if (
  //     selectedFile &&
  //     (selectedFile.type === "application/zip" ||
  //       selectedFile.type === "application/x-zip-compressed" ||
  //       selectedFile.type === "application/x-compressed")
  //   ) {
  //     setShapeFile(selectedFile);
  //   } else {
  //     setShapeFile(null);
  //     ShowNotification(
  //       "Shape file không phù hợp.",
  //       NotificationMessageType.Error
  //     );
  //   }
  // };

  const onOpenSelectZipFile = () => {
    setShowZip(true);
    setZipFilesTemp(zipFiles);
    setZipName(null);
  };

  const onCloseSelectZipFile = () => {
    setShowZip(false);
    setZipFiles(zipFilesTemp);
  };

  const onSaveSelectZipFile = () => {
    setShowZip(false);
    setZipName(zipFiles[0].fileName);
  };
  return (
    <div>
      <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="md">
        <DialogTitle disableTypography className="border-bottom">
          <Typography variant="h6">Thêm công trình BDS</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <DialogContent className="pt-4 pb-2">
            <div className="form-group row">
              <div className="col-6">
                <label className="text-dark">
                  Mã hồ sơ<span className="required"></span>
                </label>
                <TextField
                  fullWidth
                  type="text"
                  name="fileCode"
                  className="w-100"
                  inputRef={register({
                    required: true,
                    validate: handleCheckCodeExist,
                  })}
                  onChange={(e) => {
                    setValue("fileCode", changeAlias(e.target.value), {
                      shouldDirty: true,
                    });
                  }}
                  id="fileCode"
                  error={
                    (errors.fileCode && errors.fileCode.type === "required") ||
                    (errors.fileCode && errors.fileCode.type === "validate")
                  }
                  variant="outlined"
                  size="small"
                />
                {errors.fileCode && errors.fileCode.type === "required" && (
                  <span className="error">Trường này là bắt buộc</span>
                )}
                {errors.fileCode && errors.fileCode.type === "validate" && (
                  <span className="error">Mã hồ sơ đã tồn tại</span>
                )}
              </div>

              <div className="col-6">
                <label className="text-dark">
                  Mã công trình<span className="required"></span>
                </label>

                <TextField
                  fullWidth
                  inputRef={register({ required: true })}
                  type="text"
                  name="constructionCode"
                  error={
                    errors.constructionCode &&
                    errors.constructionCode.type === "required"
                  }
                  variant="outlined"
                  size="small"
                />
                {errors.constructionCode &&
                  errors.constructionCode.type === "required" && (
                    <span className="error">Trường này là bắt buộc</span>
                  )}
              </div>
            </div>
            <div className="form-group row">
              {lookupRealEstateProjectModel &&
                lookupRealEstateProjectModel.length > 0 && (
                  <div className="col-6">
                    <label className="text-dark">Tên dự án BDS</label>
                    <Autocomplete
                      options={lookupRealEstateProjectModel}
                      getOptionLabel={(option) =>
                        typeof option === "string" ? option : option.name
                      }
                      onChange={(event, newValue) =>
                        handleOnchangeRealEstateProject(event, newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          name={`realEstateProjectId`}
                          inputRef={register}
                          {...params}
                          variant="outlined"
                          size="small"
                        />
                      )}
                    />
                  </div>
                )}
              <div className="col-6">
                <label className="text-dark">Tên công trình BDS</label>

                <TextField
                  fullWidth
                  type="text"
                  name="projectName"
                  className="w-100"
                  inputRef={register}
                  variant="outlined"
                  size="small"
                />
              </div>
            </div>

            <div className="form-group row">
              {lookupConstructionRealEstateTypeModel &&
                lookupConstructionRealEstateTypeModel.length > 0 && (
                  <div className="col-6 col-md-6 col-lg-6">
                    <label className="text-dark">Loại công trình BDS</label>
                    <Autocomplete
                      options={lookupConstructionRealEstateTypeModel}
                      getOptionLabel={(option) =>
                        typeof option === "string" ? option : option.name
                      }
                      onChange={(event, newValue) =>
                        handleOnchangeContructionRealEstateType(event, newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          name="constructionRealEstateTypeId"
                          inputRef={register}
                          {...params}
                          variant="outlined"
                          size="small"
                        />
                      )}
                    />
                  </div>
                )}
              <div className="col-6">
                <label className="text-dark">Số tầng</label>

                <TextField
                  fullWidth
                  type="number"
                  name="numberFloor"
                  className="w-100"
                  inputRef={register}
                  variant="outlined"
                  size="small"
                />
              </div>
            </div>

            <div className="form-group row">
              <div className="col-6">
                <label className="text-dark">Số phòng</label>

                <TextField
                  fullWidth
                  inputRef={register}
                  type="number"
                  name="numberHouse"
                  variant="outlined"
                  size="small"
                />
              </div>
              <div className="col-6">
                <label className="text-dark">Tổng mức diện tích</label>
                <TextField
                  fullWidth
                  type="number"
                  name="totalArea"
                  className="w-100"
                  inputRef={register}
                  variant="outlined"
                  size="small"
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-6">
                <label className="text-dark">Giá bán trung bình</label>

                <TextField
                  fullWidth
                  inputRef={register}
                  type="number"
                  name="averageSalesPrice"
                  variant="outlined"
                  size="small"
                />
              </div>
              <div className="col-6">
                <label className="text-dark">Giá thuê trung bình</label>

                <TextField
                  fullWidth
                  inputRef={register}
                  type="number"
                  name="averageRentalPrice"
                  variant="outlined"
                  size="small"
                />
              </div>
            </div>

            {/* <div className="form-group row">
              {lookupLegalDocumentsModel &&
                lookupLegalDocumentsModel.length > 0 && (
                  <div className="col-6">
                    <label className="text-dark">
                      Danh sách văn bản pháp lý
                    </label>
                    <Autocomplete
                      multiple
                      options={lookupLegalDocumentsModel}
                      getOptionLabel={(option) =>
                        typeof option === "string" ? option : option.name
                      }
                      onChange={(event, newValue) =>
                        handleOnchangeLegalDocument(event, newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          name="legalDocumentsId"
                          inputRef={register}
                          {...params}
                          variant="outlined"
                          size="small"
                        />
                      )}
                    />
                  </div>
                )}
            </div> */}
            <div className="form-group row">
              <div className="col-6">
                <label className="text-dark">Shape File</label>
                <span className="required"></span>
                 <TextField
                  fullWidth
                  type="text"
                  name="shpName"
                  value={zipName}
                  placeholder="Hãy tải file zip lên."
                  disabled
                  variant="outlined"
                  size="small"
                  className="w-100"
                  inputRef={register({
                    required: true,
                  })}
                  error={
                    (errors.shpName &&
                      errors.shpName.type === "required") 
                  }
                />
                {errors.shpName &&
                  errors.shpName.type === "required" && (
                    <span className="error">Trường này là bắt buộc</span>
                  )}
               
                <div className="mt-2">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onOpenSelectZipFile}
                  >
                    Chọn shape file
                  </Button>
                </div>
              </div>
            </div>
            {/* <div className="form-group">
              <label className="text-dark">
                Shape File<span className="required"></span>
              </label>
              {shapeFile &&
                shapeFile.type === "application/x-zip-compressed" && (
                  <div style={{ width: "150px" }}>
                    <img
                      src={require("../../../assets/images/zip-file-format.png")}
                      alt={shapeFile?.name || "Default"}
                      className="img-fluid mb-2"
                      style={{
                        width: "auto",
                        height: "auto",
                        maxWidth: "100%",
                        maxHeight: "100%",
                      }}
                    />
                    <p style={{ color: "black" }} className="error">
                      {shapeFile?.name}
                    </p>
                  </div>
                )}

              {shapeFile && shapeFile.type === "application/x-compressed" && (
                <div style={{ width: "150px" }}>
                  <img
                    src={require("../../../assets/images/rar-file-format.png")}
                    alt={shapeFile?.name || "Default"}
                    className="img-fluid mb-2"
                    style={{
                      width: "auto",
                      height: "auto",
                      maxWidth: "100%",
                      maxHeight: "100%",
                    }}
                  />
                  <p style={{ color: "black" }} className="error">
                    {shapeFile?.name}
                  </p>
                </div>
              )}
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={hanleOpenSelectFile}
                >
                  Chọn file
                </Button>
                <input
                  id="shapeFile"
                  inputRef={register({ required: true })}
                  type="file"
                  name="shapeFile"
                  accept=".zip, .rar"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                  className="required"
                  required
                />
                {errors.shapeFile && errors.shapeFile.type === "required" && (
                  <p className="error">Trường này là bắt buộc</p>
                )}
              </div>
            </div> */}
          </DialogContent>

          <DialogActions className="border-top">
            <Button
              type="submit"
              onClick={onClose}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              startIcon={<SaveIcon />}
            >
              Lưu
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {isShowZip && (
        <Dialog
          onClose={onCloseSelectZipFile}
          open={isShowZip}
          fullWidth={true}
          maxWidth="md"
          className="dialog-preview-form"
        >
          <DialogTitle disableTypography>
            <Typography variant="h6">Quản lý file</Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onCloseSelectZipFile}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <FileManagement
              files={zipFiles}
              setFiles={setZipFiles}
              acceptedFiles={["zip"]}
              isShowLeft={false}
              isShowAddFolder={false}
              isShowDownload={true}
              //typeFile={"ZIP"}
              //cgisId={cgisId}
            />
          </DialogContent>

          <DialogActions>
            <Button
              type="button"
              onClick={onCloseSelectZipFile}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            {zipFiles && zipFiles.length > 0 && zipFiles !== zipFilesTemp && (
              <Button
                type="button"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={onSaveSelectZipFile}
              >
                Lưu
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
